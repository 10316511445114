import { Modal, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import CombinedButtons from "../buttons/CombinedButtons";
import CloseButton from "../buttons/CloseButton";
import { rowFlexJustifyBetween } from "../../styles/common";
import { isWindows } from "react-device-detect";

const FormModal = ({
  width = 512,
  heading = "",
  onClose = () => {},
  onSave = () => {},
  disableSave = false,
  loading = false,
  btnTxt = "Save",
  outlinedBtnText = "Cancel",
  open = false,
  children,
  hideCancel = false,
  startIcon,
  maxHeight = 88,
  headingStyle = {},
  bottomLeftAdornment,
  bottomCenterAdornment,
  isDelete = false,
  hideCombinedButton = false,
  btnStyle = {},
}) => {
  const [scale, setScale] = useState(1);

  useEffect(() => {
    if (isWindows) {
      setScale(window.devicePixelRatio - 0.07);
    }
  }, []);
  return (
    <Modal
      open={open}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          padding: "0 25px",
          borderRadius: 12,
          position: "absolute",
          background: "#ffffff",
          width,
          maxWidth: `${88 * scale}vw`,
          maxHeight: `${maxHeight * scale}vh`,
          overflow: "overlay",
        }}
      >
        <div
          className={rowFlexJustifyBetween}
          style={{
            marginBottom: 20,
            position: "sticky",
            top: 0,
            left: 0,
            right: 0,
            paddingTop: 25,
            zIndex: 2,
            background: "#ffffff",
          }}
        >
          <Typography
            style={{
              fontWeight: 700,
              fontSize: 24,
              lineHeight: "32px",
              color: "#1A1A1A",
              ...headingStyle,
            }}
          >
            {heading}
          </Typography>
          <CloseButton onClick={onClose} style={{ minWidth: 0, padding: 6 }} />
        </div>
        {children}
        <div
          style={{
            height: 84,
            position: "sticky",
            background: "#ffffff",
            zIndex: 2,
            bottom: 0,
            marginTop: 24,
          }}
        >
          {bottomLeftAdornment && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                padding: "25px 0px",
              }}
            >
              {bottomLeftAdornment}
            </div>
          )}
          {bottomCenterAdornment && (
            <div
              style={{
                position: "absolute",
                left: 0,
                right: 0,
                textAlign: "center",
                paddingTop: 12,
              }}
            >
              {bottomCenterAdornment}
            </div>
          )}
          {!hideCombinedButton && (
            <div
              style={{
                position: "absolute",
                bottom: 0,
                right: 0,
                padding: "25px 0px",
              }}
            >
              <CombinedButtons
                solidBtnText={btnTxt}
                outlinedBtnText={outlinedBtnText}
                outlinedBtnAction={onClose}
                solidBtnAction={onSave}
                loading={loading}
                disabled={disableSave}
                hideOutlineBtn={hideCancel}
                startIcon={startIcon}
                isDelete={isDelete}
                style={btnStyle}
              />
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default FormModal;
