import { Typography } from "@material-ui/core";
import React from "react";
import Moment from "react-moment";
import {
  rowFlexAlignCenter,
  rowFlexOnlyJustifyBetweenCenter,
} from "../../../styles/common";
import useStyles from "../styles";

const ChargeDetails = ({
  diamond,
  title,
  shortDescription,
  endDate,
  status,
  amount,
  term,
}) => {
  const classes = useStyles();
  return (
    <div
      className={rowFlexOnlyJustifyBetweenCenter}
      style={{ width: "100%", marginTop: 8 }}
    >
      <div className={rowFlexAlignCenter}>
        <div>{diamond}</div>
        <div
          style={{
            marginLeft: 12,
          }}
        >
          <Typography
            className={classes.charge}
            style={{ fontSize: 16, lineHeight: "20px" }}
          >
            {title}
          </Typography>
          <Typography
            className={classes.secondary}
            style={{
              fontSize: 14,
              lineHeight: "18px",
              color: "#666666",
              marginTop: 4,
            }}
          >
            Amount: {amount}, End Date:{" "}
            {endDate ? <Moment format="DD-MMM-YYYY">{endDate}</Moment> : "Forever"}
          </Typography>
        </div>
      </div>      
    </div>
  );
};
export default ChargeDetails;
