import Input from './Input';
import RadioGroup from './RadioGroup';
import Select from './Select';
import Checkbox from './Checkbox';
import RichText from './RichText';
import Switch from './Switch';
import OldSelect from './OldSelect';

const Controls = {
    Input,
    RadioGroup,
    Select,
    Checkbox,
    OldSelect,
    RichText,
    Switch
}
export default Controls;