import { Typography } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import FormModal from "../../../components/common/FormModal";
import Controls from "../../../components/controls/Controls";
import { getPartner } from "../../../utils/partnerUtils";

const DomainModal = ({ record, onSave, onClose }) => {
  const [localDomain, setLocalDomain] = useState(record.domain);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [open, setOpen] = useState(true);
  const fetchDomainDetails = (dom) => {};

  const updateDomain = async () => {
    let accessToken = localStorage.getItem("access_token");
    let data = {
      domain: localDomain,
    };
    let partner = getPartner();
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/partner/${partner.partner_slug}/shoopy-stores/${record.store_id}/domain`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  };
  return (
    <FormModal
      heading="Store Domain"
      open={open}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      onSave={async () => {
        if (localDomain === "") {
          setError(true);
          return;
        }
        setLoading(true);
        let store = await updateDomain();
        setLoading(false);
        setOpen(false);
        onSave(store);
      }}
      loading={loading}
    >
      <div style={{ height: 256, overflow: "overlay" }}>
        <Controls.Input
          endAdornment={
            <Typography
              style={{
                fontSize: 12,
                color: "#999999",
              }}
            >
              Domain
            </Typography>
          }
          label="Set Store Domain"
          value={localDomain}
          onChange={(e) => {
            setLocalDomain(e.target.value);
          }}
          onBlur={fetchDomainDetails}
          onFocus={() => setError(false)}
          labelPlacement="top"
          error={error ? "Enter valid domain name" : ""}
        />
      </div>
    </FormModal>
  );
};

export default DomainModal;
