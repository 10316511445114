import { Button } from "@material-ui/core";
import React from "react";
import CloseIcon from "../../icons/CloseIcon";

const CloseButton = ({ onClick, style = {} }) => {
  return (
    <Button
      onClick={onClick}
      style={{
        outline: "none",
        background: "none",
        padding: 6,
        minWidth: 0,
        height: 32,
        ...style,
      }}
    >
      <CloseIcon />
    </Button>
  );
};

export default CloseButton;
