import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  bannerRoot: {
    position: "relative",
    height: "100%",
  },
  icon: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
  },
  signin: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(16),
  },
  heading: {
    fontWeight: "bold",
    lineHeight: "40px",
    color: "black",
  },
  subheading: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "#666666",
    "& a": {
      color: "#1641DB",
    },
    "& span": {
      color: "#000000",
    },
  },
  subContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    marginTop: 12,
    "& div": {
      color: "#1641DB",
      fontWeight: "600",
      alignItems: "center",
      display: "flex",
      cursor: "pointer",
    },
  },
  form: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(12),
    position: "relative",
  },
  disabled: {
    background: "rgba(22, 65, 219, 0.6) !important",
    color: "#FFFFFF !important",
    boxShadow: "none",
  },
  input: {
    height: 32,
  },
  label: {
    fontSize: 14,
    lineHeight: "20px",
    color: "#666666",
    fontWeight: 600,
  },
  madeinindiactn: {
    display: "flex",
    justifyContent: "center",
    width: 320,
    position: "fixed",
    bottom: 40,
  },
  madeinindiamsg: {
    display: "flex",
    alignItems: "center",
    color: "rgba(153, 153, 153, 0.9)",
  },
  bannercontent: {
    position: "absolute",
    bottom: "10%",
    left: 120,
  },
  bannerheading: {
    fontSize: 32,
    color: "#FFFFFF",
    lineHeight: "36px",
    fontWeight: "bold",
  },
  bannersubheading: {
    fontSize: 16,
    lineHeight: "24px",
    color: "#FFFFFF",
    width: 208,
  },
  media: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderRadius: 50,
  },
  resend: {
    padding: 0,
    fontSize: 12,
    position: "absolute",
    right: 0,
    bottom: 50,
  },
  otpItem: {
    width: "48px !important",
    border: "1px solid rgba(0,0,0,0.3)",
    height: 52,
    textAlign: "center",
    margin: "0 auto",
    borderRadius: 6,
    "&::-webkit-inner-spin-button ": {
      "-webkit-appearance": "none",
    },
    "&::-webkit-outer-spin-button ": {
      "-webkit-appearance": "none",
    },
    "&:focus": {
      outline: "none",
      border: `1px solid ${theme.palette.secondary.main}`,
    },
    fontSize: 20,
  },
  errorText: {
    color: theme.palette.error.main,
  },
}));
