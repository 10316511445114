import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  tableHeader: {
    display: "flex",
    marginLeft: theme.spacing(3.25),
    marginRight: theme.spacing(4),
    // justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    alignItems: "center",
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    width: 410,
    marginRight: "18px",
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    padding: "12px",
    border: "none",
    fontSize: "12px",
    width: 390,
  },
  button: {
    background: "rgba(23, 57, 183, 0.1)",
    border: "1px solid rgba(23, 57, 183, 0.2)",
    borderRadius: "6px",
    color: "#1641DB",
    boxShadow: "none",
    padding: "12px",

    "&:hover": {
      boxShadow: "unset",
      background: "unset",
    },
  },

  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  tablebody: {
    "& tr": {
      height: "69px",
    },
  },
  applyBtn: {
    background: "#1641DB",
    color: "white",
    opacity: 0.95,
  },
  dateText: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#1A1A1A",
  },
  datePlaceholder: {
    fontSize: "12px",
    lineHeight: "18px",
    color: "#999",
  },
}));
