export const STORES_KEY = "storesPerPage";
export const SHOOPY_STORES_KEY = "shoopyStoresPerPage";
export const DEFAULT_STORES_PER_PAGE = 8;
export const STORES_PER_PAGE =
  parseInt(localStorage.getItem(STORES_KEY)) || DEFAULT_STORES_PER_PAGE;
export const SHOOPY_STORES_PER_PAGE =
  parseInt(localStorage.getItem(SHOOPY_STORES_KEY)) || DEFAULT_STORES_PER_PAGE;

export const copyToClipboard = (text) => {
  if (window.clipboardData && window.clipboardData.setData) {
    // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
    return window.clipboardData.setData("Text", text);
  } else if (
    document.queryCommandSupported &&
    document.queryCommandSupported("copy")
  ) {
    var textarea = document.createElement("textarea");
    textarea.textContent = text;
    textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
    document.body.appendChild(textarea);
    textarea.select();
    try {
      return document.execCommand("copy"); // Security exception may be thrown by some browsers.
    } catch (ex) {
      console.warn("Copy to clipboard failed.", ex);
      return false;
    } finally {
      document.body.removeChild(textarea);
    }
  }
};

export const getParticularDayTimestamp = (lastWeekDay) => {
  let currentWeekMonday = new Date().getDate() - new Date().getDay() + 1;
  return new Date().setDate(currentWeekMonday - lastWeekDay);
};

export const getDateRange = (range) => {
  let aoe = null;
  let boe = null;

  if (range === "All") {
    aoe = null;
    boe = null;
  } else if (range === "Today") {
    aoe = new Date(new Date().setHours(0, 0, 0, 0)).toISOString();
    boe = new Date(new Date().setHours(23, 59, 59, 59)).toISOString();
  } else if (range === "Yesterday") {
    aoe = new Date(
      new Date(new Date().setHours(0, 0, 0, 0)).setDate(
        new Date().getDate() - 1
      )
    ).toISOString();
    boe = new Date(
      new Date(new Date().setHours(23, 59, 59, 59)).setDate(
        new Date().getDate() - 1
      )
    ).toISOString();
  } else if (range === "Last Week") {
    aoe = new Date(
      new Date(getParticularDayTimestamp(7)).setHours(0, 0, 0, 0)
    ).toISOString();
    boe = new Date(
      new Date(getParticularDayTimestamp(1)).setHours(23, 59, 59, 59)
    ).toISOString();
  } else if (range === "Last Month") {
    aoe = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).setHours(
        0,
        0,
        0,
        0
      )
    ).toISOString();
    boe = new Date(
      new Date(new Date().getFullYear(), new Date().getMonth(), 0).setHours(
        23,
        59,
        59,
        59
      )
    ).toISOString();
  }
  return { aoe, boe };
};

export const timeSince = (date) => {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + "y ago";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + "m ago";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + "d ago";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + "h ago";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + "min ago";
  }
  return (Math.floor(seconds) > 0 ? Math.floor(seconds) : 0) + "s ago";
};

export const addYearstoDate = (dt, n) => {
  dt.setFullYear(new Date().getFullYear() + n);
  return dt;
};
export const toLocaleISOString = (inputDt) => {
  const zOffsetMs = inputDt.getTimezoneOffset() * 60 * 1000;
  const localTimeMs = inputDt - zOffsetMs;
  const date = new Date(localTimeMs);
  const utcOffsetHr = inputDt.getTimezoneOffset() / 60;
  const utcOffsetSign = utcOffsetHr <= 0 ? "+" : "-";
  const utcOffsetString =
    utcOffsetSign +
    (utcOffsetHr.toString.length === 1 ? `0${utcOffsetHr}` : `${utcOffsetHr}`) +
    ":00";
  return date.toISOString().replace("Z", utcOffsetString).substr(0, 10);
};
