export const planCustomProps = {
    FREE: {
      background:
        "linear-gradient(319.1deg, #7995FE 49.75%, rgba(121, 149, 254, 0) 162.62%)",
      isFree: true,
      validityText: "per year",
    },
    BASIC: {
      background: "linear-gradient(137.32deg, #E67682 5.16%, #CA6189 78.33%)",
      diamondProps: {},
      validityText: "per year",
    },
    BUSINESS_APP_PRO: {
      background: "linear-gradient(306.57deg, #6D74C9 6.23%, #9937E4 115.75%)",
      diamondProps: {
        primary: "#FF1D1D",
        secondary: "#2238A8",
        tertiary: "#8715F4",
        quaternary: "#B0B5F1",
        quinary: "#9973EF",
        ellipse: "#CF9EFF",
      },
      validityText: "per year",
    },
    ENTERPRISE: {
      background: "linear-gradient(314.21deg, #DECC2B 40.33%, #FFF7CC 193.99%)",
      diamondProps: { ellipse: "#DECC2B" },
      validityText: "per year",
    },
    BUSINESS: {
      background: "linear-gradient(314.21deg, #DECC2B 40.33%, #FFF7CC 193.99%)",
      diamondProps: { ellipse: "#DECC2B" },
      validityText: "per year",
    },
  };
  
  export const PLANS = {
    FREE: {
      name: "Free",
      weight: 0,
      users: 1,
      features: [
        "Unlimited Products",
        "Unlimited Orders",
        "No Commision",
        "100% Data Safety & More",
      ],
    },
    REFERRAL: {
      name: "Referral",
      weight: 0,
      users: 1,
      features: [
        "Unlimited Products",
        "Unlimited Orders",
        "No Commision",
        "100% Data Safety & More",
      ],
    },
    BASIC: {
      name: "Basic",
      weight: 1,
      users: 1,
      features: [
        "Domain Mapping",
        "No Shoopy Branding",
        "Detailed Business Reports",
        "Advanced Shipping & More",
      ],
      color: "#B3263F",
    },
    BUSINESS_APP_PRO: {
      name: "Professional",
      weight: 2,
      users: 5,
      features: [
        "All Features From Basic Plan",
        "Play Store Application file",
        "Premium Support",
        "No Shoopy Branding in SMS & More",
      ],
      color: "#9937e4",
    },
    ENTERPRISE: {
      name: "Enterprise",
      weight: 2,
      users: 5,
      features: [
        "All Features From Basic Plan",
        "Play Store Application file",
        "Premium Support",
        "No Shoopy Branding in SMS & More",
      ],
      color: "#9937e4",
    },
    BUSINESS: {
      name: "Business",
      weight: 3,
      users: 25,
      features: [
        "All Features From Professional Plan",
        "Free Domain (.in, co.in etc.)",
        "SEO at Site Level",
        "Upto 20 Staff Members",
        "Google Search",
        "Google Merchant Center Setup",
        "App Publishing",
        "Dedicated Support Manager",
      ],
      color: "#FFAC41",
    },
  };