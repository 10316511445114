import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const WalletIcon = ({
  viewBox = "0 0 16 16",
  fill = "#FC8E28",
  size = "18px",
  style,
}) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: size, height: size, ...style }}>
      <svg fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.4375 7.75C10.8573 7.75 10.3009 7.98047 9.8907 8.3907C9.48047 8.80094 9.25 9.35734 9.25 9.9375C9.25 10.5177 9.48047 11.0741 9.8907 11.4843C10.3009 11.8945 10.8573 12.125 11.4375 12.125H15.5V15.25H0.5V4.625H15.5V7.75H11.4375ZM12.0625 10.875H11.4375C11.1889 10.875 10.9504 10.7762 10.7746 10.6004C10.5988 10.4246 10.5 10.1861 10.5 9.9375C10.5 9.68886 10.5988 9.4504 10.7746 9.27459C10.9504 9.09877 11.1889 9 11.4375 9H12.0625C12.3111 9 12.5496 9.09877 12.7254 9.27459C12.9012 9.4504 13 9.68886 13 9.9375C13 10.1861 12.9012 10.4246 12.7254 10.6004C12.5496 10.7762 12.3111 10.875 12.0625 10.875ZM10.5 0.25L13 3.375H5.5L10.5 0.25Z"
          fill={fill}
        />
      </svg>
    </SvgIcon>
  );
};

export default WalletIcon;
