import React, { useState } from "react";
import Controls from "../../../components/controls/Controls";
import FormModal from "../../../components/common/FormModal";

export default function WalletModal({ open, onSave, onClose, loading }) {
  const [txnType, setTxnType] = useState("none");
  const [amount, setAmount] = useState("");

  const handleSelect = (e) => {
    setTxnType(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  return (
    <FormModal
      heading="Shoopy Wallet"
      open={open}
      onClose={onClose}
      onSave={() => onSave(txnType, amount)}
      loading={loading}
    >
      <Controls.Select
        label="Type"
        value={txnType}
        labelPlacement="top"
        onChange={handleSelect}
        placeholder="Select Type"
        options={[
          { id: "CREDIT", title: "Credit" },
          { id: "DEBIT", title: "Debit" },
        ]}
      />

      <Controls.Input
        label={txnType === "CREDIT" ? "Credit Amount" : "Debit Amount"}
        type="number"
        value={amount}
        labelPlacement="top"
        onChange={handleAmountChange}
      />
    </FormModal>
  );
}
