import React from "react";
import Lottie from "react-lottie";
import { rowFlexCenterAll } from "../../styles/common";
import Loader from "../Loader.json";

const PageLoader = () => {
  return (
    <div className={rowFlexCenterAll}>
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: Loader,
          rendererSettings: {
            preserveAspectRatio: "xMidYMid slice",
          },
        }}
        height={400}
        width={400}
      />
    </div>
  );
};

export default PageLoader;
