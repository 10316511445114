import React from "react";
import useStyles from "./styles";
import { Typography } from "@material-ui/core";

const LoginBanner = () => {
  const classes = useStyles();
  let image = "https://cdn.shpy.in/_static/site/partner_screen.png";
  return (
    <div className={classes.bannerRoot}>
      <img src={image} alt="Shoopy" className={classes.media} />
      <div className={classes.bannercontent}>
        <Typography className={classes.bannerheading}>Be a Team</Typography>
        <Typography className={classes.bannersubheading}>
          Help SMBs together in their digital journey
        </Typography>
      </div>
    </div>
  );
};

export default LoginBanner;
