import axios from "axios";
import React, { useState } from "react";
import FormModal from "../../../components/common/FormModal";
import Controls from "../../../components/controls/Controls";
import { getPartner } from "../../../utils/partnerUtils";

const ShoopyPayModal = ({ storeId, record, onSave, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);

  const initialState = {
    shoopy_per_transaction_limit: {
      value: record.shoopy_per_transaction_limit,
    },
    force_cod: {
      value: record.force_cod,
    },
    shoopy_kyc_done: {
      value: record.shoopy_kyc_done,
    },
  };
  const [formState, setFormState] = useState(initialState);

  const updatePayModal = async () => {
    let accessToken = localStorage.getItem("access_token");
    let partner = getPartner();

    let data = [
      { name: "force_cod", value: formState.force_cod.value },
      {
        name: "shoopy_per_transaction_limit",
        value: formState.shoopy_per_transaction_limit.value,
      },
      { name: "shoopy_kyc_done", value: formState.shoopy_kyc_done.value },
    ];
    let response = await axios.put(
      `${process.env.REACT_APP_API}/api/v1/partner/${partner.partner_slug}/shoopy-stores/${storeId}/attributes/payment_settings`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  };

  const onFormFieldChange = (fieldName, fieldValue) => {
    const clonedState = { ...formState };
    clonedState[fieldName].value = fieldValue;
    setFormState(clonedState);
  };

  return (
    <FormModal
      heading="Shoopy Pay"
      open={open}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      onSave={async () => {
        setLoading(true);
        let upStore = await updatePayModal(formState);
        setLoading(false);
        setOpen(false);
        onSave(upStore);
      }}
      loading={loading}
    >
      <div style={{ height: 256, overflow: "overlay" }}>
        <Controls.Input
          type="number"
          name="shoopy_per_transaction_limit"
          label="Maximum Online Payment Limit"
          labelPlacement="top"
          value={formState.shoopy_per_transaction_limit.value}
          onChange={(e) => {
            onFormFieldChange(e.target.name, e.target.value);
          }}
          error={
            formState.shoopy_per_transaction_limit.error
              ? "Enter valid limit amount"
              : ""
          }
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Controls.Checkbox
            name="force_cod"
            label={<span style={{ fontWeight: "500" }}>Force for COD</span>}
            value={formState.force_cod.value}
            onChange={(e) => {
              onFormFieldChange(e.target.name, e.target.checked);
            }}
            color="secondary"
            style={{ marginRight: 0, marginLeft: 0 }}
            checkboxStyles={{ padding: 0, marginRight: "12px" }}
          />
          <Controls.Checkbox
            name="shoopy_kyc_done"
            label={<span style={{ fontWeight: "500" }}>KYC Completed</span>}
            value={formState.shoopy_kyc_done.value}
            onChange={(e) => {
              onFormFieldChange(e.target.name, e.target.checked);
            }}
            color="secondary"
            style={{ marginRight: 0, marginLeft: 0 }}
            checkboxStyles={{ padding: 0, marginRight: "12px" }}
          />
        </div>
      </div>
    </FormModal>
  );
};

export default ShoopyPayModal;
