import { Breadcrumbs, makeStyles, Typography } from "@material-ui/core";
import { NavigateNext } from "@material-ui/icons";
import React from "react";
import StoreLink from "./StoreLink";

const useStyles = makeStyles(() => ({
  heading: {
    fontWeight: 700,
    fontSize: 24,
    lineHeight: "32px",
    color: "#1A1A1A",
    userSelect: "none",
    marginRight: "auto",
  },
  breadcrumbLink: {
    fontWeight: 600,
    fontSize: 14,
  },
  lastLabel: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    textTransform: "capitalize",
  },
}));

const HeaderBreadcrumbs = ({ list = [] }) => {
  const classes = useStyles();

  if (list.length === 1) {
    return <Typography className={classes.heading}>{list[0].label}</Typography>;
  }

  return (
    <Breadcrumbs
      style={{ fontSize: 15, marginTop: 12 }}
      separator={<NavigateNext style={{ fontSize: "1.1rem" }} />}
    >
      {list.map((item, i) => {
        if (i === list.length - 1)
          return (
            <Typography key={i} className={classes.lastLabel}>
              {item.label}
            </Typography>
          );

        return (
          <StoreLink
            className={classes.breadcrumbLink}
            key={i}
            color="secondary"
            href={item.link}
          >
            {item.label}
          </StoreLink>
        );
      })}
    </Breadcrumbs>
  );
};

export default HeaderBreadcrumbs;
