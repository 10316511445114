import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoStoresFigma = () => {
  return (
    <SvgIcon viewBox={"0 0 386 323"} style={{ width: "386px", height: "323" }}>
      <svg
        width="386"
        height="323"
        viewBox="0 0 386 323"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_12024_7730)">
          <path
            d="M196.97 208.39C250.88 208.39 294.583 164.687 294.583 110.777C294.583 56.8668 250.88 13.1641 196.97 13.1641C143.06 13.1641 99.3574 56.8668 99.3574 110.777C99.3574 164.687 143.06 208.39 196.97 208.39Z"
            fill="#DFE4F5"
          />
          <path
            opacity="0.05"
            d="M196.972 191.355C241.477 191.355 277.555 155.277 277.555 110.772C277.555 66.2675 241.477 30.1895 196.972 30.1895C152.468 30.1895 116.39 66.2675 116.39 110.772C116.39 155.277 152.468 191.355 196.972 191.355Z"
            fill="#EEEEEE"
          />
          <path
            opacity="0.05"
            d="M196.972 158.125C223.124 158.125 244.324 136.925 244.324 110.773C244.324 84.6204 223.124 63.4199 196.972 63.4199C170.82 63.4199 149.619 84.6204 149.619 110.773C149.619 136.925 170.82 158.125 196.972 158.125Z"
            fill="#EEEEEE"
          />
          <path
            d="M204.648 175.482H42.874V282.966H204.648V175.482Z"
            fill="#C6C6C6"
          />
          <path
            d="M138.558 162.682C141.64 165.076 142.768 165.848 143.424 165.75C175.23 175.012 147.347 185.867 131.894 167.442L138.558 162.682Z"
            fill="#FFB8B8"
          />
          <path
            d="M33.6045 260.536V282.282L49.2609 281.412L44.9122 258.797L33.6045 260.536Z"
            fill="#FFB8B8"
          />
          <path
            d="M120.589 260.536V282.282L104.932 281.412L109.281 258.797L120.589 260.536Z"
            fill="#FFB8B8"
          />
          <path
            d="M72.7468 148.333L96.232 147.463C150.409 204.26 129.92 191.306 128.415 269.238L99.7112 260.539L105.8 206.611C105.803 206.609 107.406 205.54 106.074 205.515C97.7401 208.379 91.6597 172.921 73.6166 177.037C53.0846 176.055 49.7421 206.513 41.4333 206.611L47.5221 260.539L18.818 269.238C15.6042 183.598 -0.146844 202.944 51.0014 147.463L72.7468 148.333Z"
            fill="#2F2E41"
          />
          <path
            d="M37.9549 275.323C37.9549 275.323 34.4756 268.364 30.1265 271.843C25.7774 275.323 15.3396 285.76 15.3396 285.76C15.3396 285.76 -9.88517 297.068 5.77158 304.027C21.4283 310.985 34.4756 294.459 34.4756 294.459C49.3885 291.349 57.9902 289.358 50.0817 273.091C45.5917 275.034 39.2377 277.284 37.9549 275.323Z"
            fill="#2F2E41"
          />
          <path
            d="M116.237 275.323C116.237 275.323 119.716 268.364 124.065 271.843C128.414 275.323 138.852 285.76 138.852 285.76C138.852 285.76 164.077 297.068 148.42 304.027C132.763 310.985 119.716 294.459 119.716 294.459C104.803 291.349 96.2015 289.358 104.11 273.091C108.6 275.034 114.954 277.284 116.237 275.323Z"
            fill="#2F2E41"
          />
          <path
            d="M96.2323 50.0431C94.9387 71.6965 65.3866 69.7901 64.9189 50.043C66.2125 28.3898 95.7646 30.2961 96.2323 50.0431Z"
            fill="#FFB8B8"
          />
          <path
            d="M73.6172 61.3477C81.2265 84.3459 63.2613 71.8249 88.4041 90.0518L97.9721 70.9158C97.9721 70.9158 91.0136 68.3063 91.8834 58.7383L73.6172 61.3477Z"
            fill="#FFB8B8"
          />
          <path
            d="M120.589 74.3949L94.6604 68.4766C95.3897 84.1109 78.705 80.9643 75.7937 73.0998L51.0039 87.4422C61.4089 126.195 58.7719 127.794 44.9151 152.679C39.6269 157.508 47.5356 160.276 54.4206 157.633C59.672 154.392 62.3389 151.512 67.9658 152.245C94.5545 157.781 116.689 159.088 120.192 156.924C121.446 155.13 120.156 74.7063 120.589 74.3949Z"
            fill="#3F3D56"
          />
          <path
            d="M113.628 76.1345C114.498 76.1345 118.978 74.0278 118.978 74.0278C132.014 73.808 140.762 161.494 141.462 163.986L132.764 170.075L118.847 134.412L113.628 76.1345Z"
            fill="#3F3D56"
          />
          <path
            d="M52.7422 177.904C53.1869 181.186 53.9438 218.051 67.1497 200.831C68.8823 188.267 62.9917 184.517 61.4404 176.164L52.7422 177.904Z"
            fill="#FFB8B8"
          />
          <path
            d="M56.2214 87.4426L53.5563 85.9651C46.4112 85.7303 48.663 104.644 45.6401 118.495C43.1705 134.631 43.8736 152.423 46.7711 176.211C47.0244 184.94 62.6592 179.441 64.9196 178.774C64.9196 178.774 59.7007 143.111 62.3101 137.022C64.9196 130.934 56.2214 87.4426 56.2214 87.4426Z"
            fill="#3F3D56"
          />
          <path
            d="M95.5474 34.8607C93.1319 30.9382 89.3509 28.0478 84.9322 26.7457L81.6868 29.342L82.9485 26.3137C81.8874 26.1518 80.8135 26.0897 79.7408 26.1283L76.8207 29.8827L78.0293 26.2569C65.4992 27.6023 58.0857 41.9864 64.5908 52.0535C65.5942 48.9697 66.8121 46.0762 67.8156 42.9925C68.6042 43.1 69.4035 43.1039 70.1932 43.004L71.414 40.1554L71.7551 42.8836C75.539 42.5539 81.1516 41.8301 84.7389 41.1669L84.3901 39.0741L86.4771 40.8132C87.5762 40.5602 88.2287 40.3308 88.1748 40.1554C90.8428 44.4565 93.4863 47.2037 96.1542 51.5048C97.167 45.438 98.9549 40.395 95.5474 34.8607Z"
            fill="#2F2E41"
          />
          <path
            d="M186.551 230.871H141.583V267.065H186.551V230.871Z"
            fill="white"
          />
          <path
            d="M177.777 238.549H150.357V242.936H177.777V238.549Z"
            fill="#E6E6E6"
          />
          <path
            d="M177.777 246.777H150.357V251.164H177.777V246.777Z"
            fill="#E6E6E6"
          />
          <path
            d="M177.777 255.002H150.357V259.389H177.777V255.002Z"
            fill="#E6E6E6"
          />
          <path
            d="M257.525 268.843H331.522V268.292H258.058V241.554H325.302L328.568 234.506L328.088 234.268L324.967 241.002H257.525V268.843Z"
            fill="#2F2E41"
          />
          <path
            d="M321.829 282.418C318.795 282.418 316.336 279.814 316.336 276.602C316.336 273.389 318.795 270.785 321.829 270.785C324.863 270.785 327.322 273.389 327.322 276.602C327.322 279.814 324.863 282.418 321.829 282.418Z"
            fill="#3F3D56"
          />
          <path
            d="M266.733 282.418C263.61 282.418 261.078 279.814 261.078 276.602C261.078 273.389 263.61 270.785 266.733 270.785C269.856 270.785 272.388 273.389 272.388 276.602C272.388 279.814 269.856 282.418 266.733 282.418Z"
            fill="#3F3D56"
          />
          <path
            d="M201.623 157.367C199.66 157.367 198.069 155.775 198.069 153.812C198.069 151.849 199.66 150.258 201.623 150.258C203.586 150.258 205.178 151.849 205.178 153.812C205.178 155.775 203.586 157.367 201.623 157.367Z"
            fill="#3F3D56"
          />
          <path
            d="M249.588 232.658H332.886L350.909 171.586H231.027L231.136 171.945L249.588 232.658ZM332.491 232.105H249.979L231.754 172.139H350.189L332.491 232.105Z"
            fill="#2F2E41"
          />
          <path
            d="M256.061 229.426H329.255L345.093 175.463H239.752L239.847 175.781L256.061 229.426Z"
            fill="#F2F2F2"
          />
          <path
            d="M229.544 166.414L230.058 166.272L226.55 152.52H206.146V153.073H226.141L229.544 166.414Z"
            fill="#2F2E41"
          />
          <path
            d="M237.166 190.324H345.093V190.647H237.166V190.324Z"
            fill="#2F2E41"
          />
          <path
            d="M243.307 210.361H338.954V211.008H243.307V210.361Z"
            fill="#2F2E41"
          />
          <path
            d="M290.808 171.906H291.131V232.332H290.808V171.906Z"
            fill="#2F2E41"
          />
          <path
            d="M266.896 171.984L270.721 232.258L270.189 232.294L266.365 172.02L266.896 171.984Z"
            fill="#2F2E41"
          />
          <path
            d="M315.122 171.988L315.653 172.023L311.851 232.298L311.319 232.263L315.122 171.988Z"
            fill="#2F2E41"
          />
          <path
            d="M379.583 282.42H206.293V283.517H379.583V282.42Z"
            fill="#A8A8A8"
          />
        </g>
        <defs>
          <clipPath id="clip0_12024_7730">
            <rect
              width="384.968"
              height="323"
              fill="white"
              transform="translate(0.648438)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
};

export default NoStoresFigma;
