import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_SHOOPY_STORES,
  SET_SHOOPY_STORE_ERROR,
  SET_SHOOPY_STORE_LOADER,
  SHOOPY_STORE_PROGRESS,
  CLEAR_SHOOPY_STORE,
  CLEAR_SHOOPY_COMPLETE_STORES,
  SET_SHOOPY_STORES_PER_PAGE,
  SET_SHOOPY_STORES_SEARCH_KEYWORD,
  SET_SHOOPY_STORES_INPUT_KEYWORD,
  SET_SHOOPY_STORES_CURRENT_PAGE,
  SET_SHOOPY_STORES_MAX_PAGE_SO_FAR,
  SET_SHOOPY_SUCCESS_TOAST,
  UPDATE_SHOOPY_STORE
} from "./shoopyStoreTypes";

const initialState = {
  stores: [],
  numberOfPages: 0,
  numberOfElements: 0,
  store: null,
  progress: false,
  loader: false,
  error: null,
  pageDirty: false,
  storesPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
  searchKeyword: "",
  inputKeyword: "",
  successToast: false,
  message: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SHOOPY_STORE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case UPDATE_SHOOPY_STORE:
      let modStore = action.payload;
      let modStores = state.stores.map((st) => {
        if (st.store_id === modStore.store_id) {
          return modStore;
        }
        return st;
      });
      return {
        ...state,
        stores: modStores
      };
    case FETCH_SHOOPY_STORES:
      let allStores = state.stores;
      let page = action.payload.pageable.pageNumber;
      if (page > 0 && state.stores.length > action.storesPerPage * page) {
        allStores = state.stores.slice(0, action.storesPerPage * page);
      }
      return {
        ...state,
        pageDirty: false,
        stores:
          action.payload.pageable.pageNumber > 0
            ? [...allStores, ...action.payload.content]
            : action.payload.content,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };

    case CLEAR_SHOOPY_STORE:
      return {
        ...state,
        store: action.payload,
      };
    case SET_SHOOPY_STORE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_SHOOPY_STORE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_SHOOPY_COMPLETE_STORES:
      return {
        ...state,
        stores: [],
        numberOfPages: 0,
        numberOfElements: 0,
        store: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        successToast: false,
        message: "",
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        stores: [],
        numberOfPages: 0,
        numberOfElements: 0,
        store: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        storesPerPage: null,
        searchKeyword: "",
        inputKeyword: "",
        currentPage: 0,
        maxPageSoFar: 0,
        successToast: false,
        message: "",
      };
    case SET_SHOOPY_STORES_PER_PAGE:
      return {
        ...state,
        storesPerPage: action.payload,
      };
    case SET_SHOOPY_STORES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_SHOOPY_STORES_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_SHOOPY_STORES_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_SHOOPY_STORES_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case SET_SHOOPY_SUCCESS_TOAST:
      return {
        ...state,
        successToast: action.payload,
        meassage: "",
      };
    default:
      return state;
  }
};

export default reducer;
