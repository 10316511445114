import { Typography } from "@material-ui/core";
import React from "react";
import ShoopyIconLatest from "../../icons/ShoopyIconLatest";
import MobileDrawing from "./MobileDrawing";

const MobileMode = () => {
  return (
    <div
      style={{
        background: "#FFFFFF",
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "8vh",
      }}
    >
      <div style={{ textAlign: "center", padding: 20 }}>
        <ShoopyIconLatest />
        <Typography style={{ marginTop: 40, fontWeight: 600 }} variant="h5">
          Welcome To Shoopy
        </Typography>
        <Typography style={{ marginTop: 12, marginBottom: 50 }} variant="body2">
          To access our Desktop App please access it on a PC or a Laptop
        </Typography>
        <div style={{ width: "100%", textAlign: "center" }}>
          <MobileDrawing />
        </div>
      </div>
    </div>
  );
};

export default MobileMode;
