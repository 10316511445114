import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Route, Switch, Redirect, BrowserRouter } from "react-router-dom";
import Layout from "./components/layout/Layout";
import MobileMode from "./components/layout/MobileMode";
import NewLogin from "./pages/login/NewLogin";
import "./App.css";
import { isMobile, isWindows } from "react-device-detect";

function App() {
  let isAuthenticated = localStorage.getItem("access_token") ? true : false;
  let subscribeUrl = null;
  if (/subscribe/.test(window.location.hash)) {
    let url = window.location.hash;
    subscribeUrl = url.replace("#", "");
  }
  if (
    !isAuthenticated &&
    window.location.pathname !== "/login" &&
    window.location.pathname !== "/login/redirect" &&
    !/subscribe/.test(window.location.pathname) &&
    !subscribeUrl
  ) {
    window.location.assign("/login");
  }

  const reload = useSelector((state) => state.reload);

  if (reload) {
    setTimeout(() => {
      if (isWindows) {
        const scale = window.devicePixelRatio - 0.07;
        const elements = document.getElementsByClassName("MuiPopover-paper");
        Array.prototype.map.call(elements, (element) => {
          const width = element.offsetWidth > 200 ? 200 : element.offsetWidth;
          element.style.left = `${parseInt(
            element.style.left.slice(0, -2) * scale -
              width +
              width * ((scale % 1) + 0.07)
          )}px`;
        });
      }
    }, 10);
  }

  useEffect(() => {
    if (isWindows) {
      document.body.style.zoom = 1 / window.devicePixelRatio + 0.07;
    }
  }, []);

  return (
    <>
      <BrowserRouter>
        {isMobile && (
          <Switch>
            {!subscribeUrl ? (
              <Route path="/" component={MobileMode} />
            ) : (
              <Redirect to={subscribeUrl} />
            )}
          </Switch>
        )}
        {!isMobile && (
          <Switch>
            {isAuthenticated ? (
              <Route
                exact
                path="/"
                render={() =>
                  subscribeUrl ? (
                    <Redirect to={subscribeUrl} />
                  ) : (
                    <Redirect to={`/stores`} />
                  )
                }
              />
            ) : (
              <Route
                exact
                path="/"
                render={() =>
                  subscribeUrl ? (
                    <Redirect to={subscribeUrl} />
                  ) : (
                    <Redirect to="/login" />
                  )
                }
              />
            )}
            <PublicRoute path="/login" component={NewLogin} />
            <PrivateRoute path="/" component={Layout} />
          </Switch>
        )}
      </BrowserRouter>
      <style global="true" jsx="true">
        {`
          .MuiOutlinedInput-input {
            font-size: 14px;
            padding: 9px 9px;
          }
          .MuiFormHelperText-contained {
            margin: 2px;
            line-height: 1.35;
          }
          .MuiOutlinedInput-multiline {
            padding: 3px;
          }
          .MuiFormLabel-root {
            font-size: 14px;
          }
        `}
      </style>
    </>
  );
}

function PrivateRoute({ component, ...rest }) {
  const customer = useSelector((state) => state.customer);
  var isAuthenticated = customer.isAuthenticated;
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          React.createElement(component, props)
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: {
                from: props.location,
              },
            }}
          />
        )
      }
    />
  );
}

function PublicRoute({ component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => React.createElement(component, props)}
    />
  );
}

export default App;
