import { STORES_PER_PAGE } from "../../utils/utils";
import {
  FETCH_STORES,
  STORE_PROGRESS,
  SET_STORE_ERROR,
  SET_STORE_LOADER,
  CLEAR_STORE,
  CLEAR_COMPLETE_STORES,
  SET_STORES_PER_PAGE,
  SET_STORES_INPUT_KEYWORD,
  SET_STORES_SEARCH_KEYWORD,
  SET_STORES_CURRENT_PAGE,
  SET_STORES_MAX_PAGE_SO_FAR,
  SET_SUCCESS_TOAST,
  STORE_DATE_RANGE,
} from "./storeTypes";
import axios from "axios";
import { getPartner } from "../../utils/partnerUtils";

export const fetchStores =
  (page = 0, keyword = null, aoe = null, boe = null, size = STORES_PER_PAGE) =>
  (dispatch) => {
    dispatch({
      type: STORE_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let partner = getPartner();
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/partner/${
          partner.partner_slug
        }/stores?page=${page}&size=${size}&sort=createdAt,desc${
          keyword ? `&query=${keyword}` : ""
        }${aoe && boe ? `&created-aoe=${aoe}&created-boe=${boe}` : ""}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_STORES,
          payload: response.data.payload,
          storesPerPage: size,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_STORE_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearStore = () => (dispatch) => {
  dispatch({ type: CLEAR_STORE, payload: null });
};

export const clearStores = () => (dispatch) => {
  dispatch({
    type: CLEAR_COMPLETE_STORES,
  });
};

export const startStoreLoader = () => (dispatch) => {
  dispatch({
    type: SET_STORE_LOADER,
    payload: true,
  });
};

export const stopStoreLoader = () => (dispatch) => {
  dispatch({
    type: SET_STORE_LOADER,
    payload: false,
  });
};

export const setStoresPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_STORES_PER_PAGE,
    payload: data,
  });
};

export const setStoresCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_STORES_CURRENT_PAGE,
    payload: data,
  });
};

export const setStoresMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_STORES_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setStoresInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_STORES_INPUT_KEYWORD,
    payload: data,
  });
};

export const setStoresSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_STORES_SEARCH_KEYWORD,
    payload: data,
  });
};
export const setStoreDateRange = (data) => (dispatch) => {
  dispatch({
    type: STORE_DATE_RANGE,
    payload: data,
  });
};

export const setSuccessToast = (data) => (dispatch) => {
  dispatch({
    type: SET_SUCCESS_TOAST,
    payload: data,
  });
};
