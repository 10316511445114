import React from "react";
import {
  FormControlLabel,
  Switch as MuiSwitch,
  Typography,
} from "@material-ui/core";

const Switch = (props) => {
  const {
    name,
    label,
    value,
    color,
    onChange,
    width = "auto",
    labelPlacement = "start",
    style = {},
    helperText = "",
  } = props;
  return (
    <FormControlLabel
      control={
        <MuiSwitch
          checked={value}
          onChange={onChange}
          name={name}
          color={color}
        />
      }
      labelPlacement={labelPlacement}
      label={
        helperText ? (
          <div>
            {label}
            <Typography
              style={{ fontSize: "12px", fontWeight: 400, color: "#666666" }}
            >
              {helperText}
            </Typography>
          </div>
        ) : (
          label
        )
      }
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: width,
        ...style,
      }}
    />
  );
};
export default Switch;
