import React, { useState } from "react";
import { useDispatch } from "react-redux";
import useStyles from "./styles";
import { rowFlexAlignCenter } from "../../styles/common";
import ProfileIcon from "../../icons/ProfileIcon";
import { ClickAwayListener } from "@material-ui/core";
import CircleOutIcon from "../../icons/CircleOutIcon";
import { useHistory } from "react-router-dom";
import { CLEAR_COMPLETE } from "../../redux/commom/commonTypes";
import { customerLogout } from "../../redux/customer/customerActions";
import { getPartner } from "../../utils/partnerUtils";

const Header = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const partner = getPartner();
  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const list = [
    {
      icon: <CircleOutIcon stroke="#FF1D1D" />,
      label: "Sign Out",
      color: "#FF1D1D",
      onClick: () => {
        setOpenProfileMenu(false);
        dispatch(customerLogout());
        dispatch({
          type: CLEAR_COMPLETE,
          payload: {},
        });
        history.push("/login");
      },
    },
  ];

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        <img
          src={
            partner.partner_slug && partner.partner_slug === "ninjacart"
              ? "https://cdn.shpy.in/_static/app-img/ninjaagro_logo.png"
              : "https://cdn.shpy.in/static/shoopy-app/shoopysitelogo.png"
          }
          style={{ height: 32 }}
          alt="logo"
        />
      </div>
      <div className={rowFlexAlignCenter}>
        <ClickAwayListener
          onClickAway={() => {
            setOpenProfileMenu(false);
          }}
        >
          <div style={{ position: "relative" }}>
            <div
              style={{ cursor: "pointer" }}
              onClick={() => setOpenProfileMenu(!openProfileMenu)}
            >
              <ProfileIcon />
            </div>
            <div
              className={classes.arrow}
              style={{
                visibility: openProfileMenu ? "visible" : "hidden",
              }}
            />
            <div
              className={classes.sheet}
              style={{
                visibility: openProfileMenu ? "visible" : "hidden",
              }}
            >
              <div className={classes.profileCard}>
                <ProfileIcon size={34} />
                <div style={{ marginLeft: 12 }}>
                  <div style={{ textTransform: "capitalize" }}>
                    User
                  </div>
                  <div className={classes.phone}>{partner.mobile}</div>
                </div>
              </div>
              {list.map((obj, i) => (
                <div key={i} className={classes.item} onClick={obj.onClick}>
                  <div className={classes.icon}>{obj.icon}</div>
                  <div className={classes.label} style={{ color: obj.color }}>
                    {obj.label}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};
export default Header;
