import { Link } from "@material-ui/core";
import React from "react";
import { useHistory, useParams } from "react-router-dom";

const StoreLink = (props) => {
  const history = useHistory();
  const params = useParams();

  return (
    <Link
      {...props}
      onClick={(e) => {
        e.preventDefault();
        history.push(`/${params.storeId}${props.href}`);
      }}
    >
      {props.children}
    </Link>
  );
};

export default StoreLink;
