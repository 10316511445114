import { makeStyles } from "@material-ui/styles";
export default makeStyles((theme) => ({
  nostore: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    marginTop: 60,
  },
  loginbtn: {
    width: 110,
    height: 32,
    outline: "none",
    textTransform: "none",
    fontSize: 12,
    padding: 8,
  },
  nomessage: {
    fontWeight: 600,
    fontSize: 20,
    lineHeight: "32px",
    color: "#1A1A1A",
  },
  tableHeader: {
    display: "flex",
    marginLeft: theme.spacing(3.25),
    marginRight: theme.spacing(4),
    // justifyContent: "space-between",
    marginBottom: theme.spacing(3),
    alignItems: "center",
  },
  search: {
    display: "flex",
    border: "1px solid #E6E6E6",
    borderRadius: 6,
    padding: "12px",
    paddingTop: 9,
    height: "44px",
    width: 410,
    marginRight: "18px",
  },
  searchIcon: {
    height: "100%",
  },
  searchBox: {
    outlineWidth: 0,
    lineHeight: "16px",
    padding: "12px",
    border: "none",
    fontSize: "12px",
    width: 390,
  },
  tablehead: {
    backgroundColor: "#FFFFFF",
    boxShadow: "0px -1px 0px #E5E5E5, 0px 1px 0px #E6E6E6",
    borderTop: "1px solid #E6E6E6",
  },
  tablebody: {
    "& tr": {
      height: "69px",
    },
  }
}));
