import { Button, makeStyles } from "@material-ui/core";
import React from "react";
import Lottie from "react-lottie";
import { blueButton, delButton } from "../../styles/common";
import ComponentLoaderWhite from "../ComponentLoaderWhite.json";

const SolidButton = ({
  onClick = () => {},
  loading = false,
  disabled = false,
  style = {},
  children,
  isDelete = false,
  endIcon = <></>,
  startIcon = <></>,
  key = 1,
  fullWidth = false,
}) => {
  const { disabledBtn, endIconCss, startIconCss } = makeStyles(() => ({
    disabledBtn: {
      background: "#C4C4C4 !important",
      color: "#666666 !important",
    },
    endIconCss: {
      marginRight: 8,
    },
    startIconCss: {
      marginLeft: 8,
    },
  }))();

  return (
    <Button
      type="submit"
      className={isDelete ? delButton : blueButton}
      style={style}
      onClick={(e) => {
        if (!loading) onClick(e);
      }}
      disabled={disabled}
      classes={{
        disabled: disabledBtn,
        endIcon: endIconCss,
        startIcon: startIconCss,
      }}
      endIcon={endIcon}
      startIcon={startIcon}
      key={key}
      fullWidth={fullWidth}
    >
      {loading ? (
        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: ComponentLoaderWhite,
            rendererSettings: {
              preserveAspectRatio: "xMidYMid slice",
            },
          }}
          height={24}
          width={24}
          style={{
            overflow: "visible",
            marginTop: -8,
          }}
        />
      ) : (
        children
      )}
    </Button>
  );
};

export default SolidButton;
