import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import useStyles from "./styles";
import Header from "../Header/Header";
import Stores from "../../pages/stores/Stores";
import ShoopyStores from "../../pages/shoopystores/index";

const Layout = () => {
  const classes = useStyles();

  return (
    <>
      <div>
        <Header />
        <div className={classes.content}>
          <Switch>
            <Route exact path="/stores" component={Stores} />
          </Switch>
          <Switch>
            <Route exact path="/shoopy-stores" component={ShoopyStores} />
          </Switch>
        </div>
      </div>
    </>
  );
};
export default withRouter(Layout);
