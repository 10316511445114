import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { Grid } from "@material-ui/core";
import { useLocation, withRouter } from "react-router-dom";
import LoginBanner from "./components/LoginBanner";
import SignIn from "./components/SignIn";
import { useDispatch, useSelector } from "react-redux";
import {
  customerLoginSuccess,
  customerLogout,
  fetchPartner,
} from "../../redux/customer/customerActions";
import { CLEAR_COMPLETE } from "../../redux/commom/commonTypes";
import Toast from "../../components/layout/Toast";
import axios from "axios";

const NewLogin = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const customer = useSelector((state) => state.customer);
  const [isOpenToast, setIsOpenToast] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const location = useLocation();
  let query = new URLSearchParams(location.search);
  let callback = query.get("callback");
  let token = query.get("token");
  const onSuccess = async () => {
    dispatch(fetchPartner()).then((res) => {
      if (res?.partner_slug) {
        window.location =
          callback && callback.startsWith("/") ? callback : `/stores`;
        document.body.style.backgroundColor = "#F9F9F9";
      } else {
        dispatch(customerLogout());
        dispatch({
          type: CLEAR_COMPLETE,
          payload: {},
        });
        setIsOpenToast(true);
        setOtpSent(false);
      }
    });
  };

  const onSubmit = async (mobile, otp) => {
    return await axios.post(
      `${process.env.REACT_APP_AUTH_API}/api/v1/auth/oauth/token`,
      `grant_type=password&username=${mobile}&password=${otp}`,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Basic ${process.env.REACT_APP_DEVICE_TOKEN}`,
        },
      }
    );
  };

  useEffect(() => {
    if (token) {
      dispatch(customerLogout());
      dispatch({
        type: CLEAR_COMPLETE,
        payload: {},
      });
      const [mobile, otp] = Buffer.from(token, "base64")
        .toString("ascii")
        .split(":");
      onSubmit(mobile, otp).then((response) => {
        dispatch(customerLoginSuccess(response.data));
        onSuccess();
      });
    } else if (customer.isAuthenticated) {
      window.location =
        callback && callback.startsWith("/") ? callback : `/stores`;
    } else {
      document.body.style.backgroundColor = "#FFFFFF";
    }
    // eslint-disable-next-line
  }, []);

  return (
    <div>
      <Grid
        container
        className={classes.root}
        style={{
          height: "100vh",
        }}
      >
        <Grid item md={5} xs={12}>
          <SignIn
            onSuccess={onSuccess}
            otpSent={otpSent}
            setOtpSent={setOtpSent}
            onSubmit={onSubmit}
          />
        </Grid>
        <Grid item md={7} className={classes.banner}>
          <LoginBanner />
        </Grid>
      </Grid>
      <Toast
        open={isOpenToast}
        close={() => setIsOpenToast(false)}
        message="Account is not registered with this number"
        severity="error"
      />
    </div>
  );
};

export default withRouter(NewLogin);
