import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const LoginIcon = ({ viewBox = "0 0 16 17", size = 16 }) => {
  return (
    <SvgIcon viewBox={viewBox} style={{ width: size, height: size }}>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.7752 17V16H14.2002C14.3502 16 14.4879 15.9373 14.6132 15.812C14.7379 15.6873 14.8002 15.5417 14.8002 15.375V1.625C14.8002 1.45833 14.7379 1.31267 14.6132 1.188C14.4879 1.06267 14.3502 1 14.2002 1H7.7752V0H14.2002C14.6502 0 15.0292 0.154333 15.3372 0.463C15.6459 0.771 15.8002 1.15833 15.8002 1.625V15.375C15.8002 15.8417 15.6459 16.2293 15.3372 16.538C15.0292 16.846 14.6502 17 14.2002 17H7.7752ZM7.2252 12.05L6.5252 11.325L8.8502 9H0.200195V8H8.8502L6.5252 5.675L7.2252 4.95L10.7752 8.5L7.2252 12.05Z"
          fill="#1641DB"
        />
      </svg>
    </SvgIcon>
  );
};

export default LoginIcon;
