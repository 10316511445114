export const setupPartner = (partnerData) => {
  localStorage.setItem("partner", JSON.stringify(partnerData));
};

export const isLoggedIn = () => {
  let token = localStorage.getItem("access_token");
  return token !== null && token !== "";
};

export const getPartner = () => {
  let value = localStorage.getItem("partner");
  let partner = {};
  if (value) {
    partner = JSON.parse(value);
  }
  return partner;
};
