import React from "react";
import { rowFlexAlignCenter } from "../../styles/common";
import OutlinedButton from "./OutlinedButton";
import SolidButton from "./SolidButton";

const CombinedButtons = ({
  outlinedBtnText = "Cancel",
  solidBtnText = "Save",
  outlinedBtnAction = () => {},
  solidBtnAction = () => {},
  style = {},
  containerCss = {},
  disabled = false,
  disableOutline = false,
  loading = false,
  isDelete = false,
  endIcon = <></>,
  startIcon = <></>,
  hideOutlineBtn = false,
}) => {
  return (
    <div style={containerCss} className={rowFlexAlignCenter}>
      {!hideOutlineBtn && (
        <OutlinedButton
          onClick={outlinedBtnAction}
          style={{ ...style, marginRight: 20 }}
          disabled={disableOutline}
        >
          {outlinedBtnText}
        </OutlinedButton>
      )}
      <SolidButton
        onClick={solidBtnAction}
        style={style}
        disabled={disabled}
        loading={loading}
        isDelete={isDelete}
        endIcon={endIcon}
        startIcon={startIcon}
      >
        {solidBtnText}
      </SolidButton>
    </div>
  );
};

export default CombinedButtons;
