import {
  CUSTOMER_LOGIN_SUCCESS,
  CUSTOMER_LOGOUT,
  TRIGGER_LOGIN,
  CLEAR_TRIGGER_LOGIN,
} from "./customerTypes";

const initialState = {
  customer: {},
  isAuthenticated: Boolean(localStorage.getItem("access_token")),
  reLogin: false,
  error: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case CUSTOMER_LOGIN_SUCCESS:
      return {
        ...state,
        customer: action.payload.customer,
        isAuthenticated: action.payload.token !== "" ? true : false,
      };
    case CUSTOMER_LOGOUT:
      return {
        ...state,
        customer: {},
        isAuthenticated: false,
      };
    case TRIGGER_LOGIN:
      return {
        ...state,
        reLogin: true,
      };
    case CLEAR_TRIGGER_LOGIN:
      return {
        ...state,
        reLogin: false,
      };
    default:
      return state;
  }
};
export default reducer;
