import React, { Fragment, useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  LinearProgress,
  Typography,
  Box,
  Button,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStores,
  clearStores,
  setStoresPerPage,
  setStoresInputKeyword,
  setStoresSearchKeyword,
  setStoresCurrentPage,
  setStoresMaxPageSoFar,
  setStoreDateRange,
} from "../../redux/stores/storeActions";
import {
  STORES_PER_PAGE,
  DEFAULT_STORES_PER_PAGE,
  STORES_KEY,
} from "../../utils/utils";
import Paginator from "../../components/Paginator/Paginator";
import Toast from "../../components/layout/Toast";
import {
  layoutBodyBox,
  layoutContainer,
  layoutLoaderHeader,
  layoutSubHeader,
  rowFlexAlignCenter,
} from "../../styles/common";
import PageLoader from "../../components/layout/PageLoader";
import HeaderBreadcrumbs from "../../components/common/HeaderBreadcrumbs";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-daterangepicker/daterangepicker.css";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DateRangeOutlinedIcon from "@material-ui/icons/DateRangeOutlined";
import Moment from "react-moment";
import axios from "axios";
import LoginIcon from "../../icons/LoginIcon";
import NoStoresFigma from "../../icons/NoStoreFigma";

const Stores = () => {
  const classes = useStyles();
  const storeState = useSelector((state) => state.stores);
  const stores = storeState.stores;
  const numberOfPages = storeState.numberOfPages;
  const storesPerPage = storeState.storesPerPage;
  const currentPage = storeState.currentPage;
  const maxPageSoFar = storeState.maxPageSoFar;
  const searchKeyword = storeState.searchKeyword;
  const inputKeyword = storeState.inputKeyword;
  const dateRange = storeState.dateRange;
  const [whiteSpaceDiv, setWhiteSpaceDiv] = useState(false);
  const [toast, setToast] = useState(false);

  const onChangeStoresPerPage = (updatedStoresPerPage) => {
    localStorage.setItem(STORES_KEY, updatedStoresPerPage);
    dispatch(clearStores());
    dispatch(setStoresCurrentPage(0));
    dispatch(setStoresMaxPageSoFar(0));
    dispatch(setStoresPerPage(updatedStoresPerPage));
    dispatch(
      fetchStores(
        0,
        searchKeyword,
        dateRange.aoe,
        dateRange.boe,
        updatedStoresPerPage
      )
    );
  };
  let startIndex = currentPage * storesPerPage;
  let endIndex =
    startIndex + storesPerPage < stores.length
      ? startIndex + storesPerPage
      : stores.length;

  const dispatch = useDispatch();

  useEffect(() => {
    if (stores.length === 0) {
      dispatch(fetchStores());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!storesPerPage) {
      dispatch(setStoresPerPage(STORES_PER_PAGE));
    }
    // eslint-disable-next-line
  }, [storesPerPage]);

  const onNextPage = () => {
    setWhiteSpaceDiv(false);
    if (maxPageSoFar < currentPage + 1) {
      dispatch(
        fetchStores(
          currentPage + 1,
          searchKeyword,
          dateRange.aoe,
          dateRange.boe,
          storesPerPage
        )
      );
      dispatch(
        setStoresMaxPageSoFar(
          maxPageSoFar < currentPage + 1 ? currentPage + 1 : maxPageSoFar
        )
      );
      dispatch(setStoresCurrentPage(currentPage + 1));
    } else {
      dispatch(setStoresCurrentPage(currentPage + 1));
    }
  };

  const onPrevPage = () => {
    dispatch(setStoresCurrentPage(currentPage - 1));
  };

  const onSearch = (keyword) => {
    setWhiteSpaceDiv(true);
    if (keyword) {
      dispatch(setStoresMaxPageSoFar(0));
      dispatch(setStoresCurrentPage(0));
      dispatch(
        fetchStores(0, keyword, dateRange.aoe, dateRange.boe, storesPerPage)
      );
    }
  };

  const handleCustomDateSelector = (rangePicker) => {
    setWhiteSpaceDiv(true);
    dispatch(setStoresMaxPageSoFar(0));
    dispatch(setStoresCurrentPage(0));
    dispatch(
      fetchStores(
        0,
        searchKeyword,
        rangePicker.startDate._d.toISOString(),
        rangePicker.endDate._d.toISOString(),
        storesPerPage
      )
    );
    dispatch(
      setStoreDateRange({
        aoe: rangePicker.startDate._d.toISOString(),
        boe: rangePicker.endDate._d.toISOString(),
      })
    );
  };

  const onLoginClick = (storeId) => {
    let accessToken = localStorage.getItem("access_token");
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/partner-users/login-token?store-id=${storeId}`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        let link = `${process.env.REACT_APP_WEB}/login?token=${response.data.payload}`;
        window.open(link);
      })
      .catch((error) => {
        setToast(true);
      });
  };

  if (storeState.progress && stores.length === 0) {
    return (
      <div>
        <Grid container spacing={2}>
          <Grid item xs={12} className={layoutLoaderHeader}>
            <div className={layoutContainer}>
              <HeaderBreadcrumbs list={[{ label: "Stores" }]} />
              <PageLoader />
            </div>
          </Grid>
        </Grid>
      </div>
    );
  }
  return (
    <Fragment>
      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={layoutContainer}>
            <div className={layoutSubHeader}>
              <HeaderBreadcrumbs list={[{ label: "Stores" }]} />
            </div>
            {stores.length === 0 &&
            searchKeyword === "" &&
            dateRange.aoe === null &&
            dateRange.boe === null ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  marginTop: 60,
                }}
              >
                <div>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 20,
                      lineHeight: "32px",
                      color: "#1A1A1A",
                    }}
                  >
                    No Store found, please create store
                  </p>
                </div>
                <div>
                  <NoStoresFigma />
                </div>
              </div>
            ) : (
              <div style={{ position: "relative", width: "100%" }}>
                <div className={layoutBodyBox}>
                  <div className={classes.tableHeader}>
                    <div className={classes.search}>
                      <SearchIcon />
                      <input
                        type="text"
                        placeholder="Search by Phone Number"
                        className={classes.searchBox}
                        value={inputKeyword}
                        onChange={(e) => {
                          dispatch(setStoresInputKeyword(e.target.value));
                        }}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") {
                            if (inputKeyword.trim() !== "") {
                              dispatch(setStoresSearchKeyword(inputKeyword));
                              onSearch(inputKeyword.trim());
                            }
                          }
                          if (e.key === "Escape") {
                            setWhiteSpaceDiv(true);
                            dispatch(setStoresInputKeyword(""));
                            dispatch(setStoresSearchKeyword(""));
                            dispatch(setStoresMaxPageSoFar(0));
                            dispatch(setStoresCurrentPage(0));
                            dispatch(
                              fetchStores(
                                0,
                                null,
                                dateRange.aoe,
                                dateRange.boe,
                                storesPerPage
                              )
                            );
                          }
                        }}
                      />
                      {searchKeyword !== "" && (
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setWhiteSpaceDiv(true);
                            dispatch(setStoresSearchKeyword(""));
                            dispatch(setStoresInputKeyword(""));
                            dispatch(setStoresMaxPageSoFar(0));
                            dispatch(setStoresCurrentPage(0));
                            dispatch(
                              fetchStores(
                                0,
                                null,
                                dateRange.aoe,
                                dateRange.boe,
                                storesPerPage
                              )
                            );
                          }}
                        >
                          <CloseIcon fontSize="small" />
                        </span>
                      )}
                    </div>
                    <div>
                      <DateRangePicker
                        initialSettings={{
                          startDate: new Date(
                            new Date().setMonth(new Date().getMonth() - 1)
                          ),
                          endDate: new Date(),
                          maxDate: new Date(),
                          applyButtonClasses: classes.applyBtn,
                        }}
                        onApply={(fn, rangePicker) => {
                          handleCustomDateSelector(rangePicker);
                        }}
                      >
                        <Box
                          margin="0px 8px"
                          border="1px solid #e6e6e6"
                          display="flex"
                          borderRadius="4px"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <Box
                            padding="9px"
                            borderRight="1px solid #e6e6e6"
                            style={{ cursor: "pointer" }}
                          >
                            <DateRangeOutlinedIcon />
                          </Box>
                          <Box padding="9px" className={rowFlexAlignCenter}>
                            {dateRange.aoe && dateRange.boe ? (
                              <Typography className={classes.dateText}>
                                <Moment format="DD-MM-YYYY">
                                  {dateRange.aoe}
                                </Moment>
                                <span> - </span>
                                <Moment format="DD-MM-YYYY">
                                  {dateRange.boe}
                                </Moment>
                              </Typography>
                            ) : (
                              <Typography className={classes.datePlaceholder}>
                                dd/mm/yyyy - dd/mm/yyyy
                              </Typography>
                            )}
                            {dateRange.aoe && dateRange.boe && (
                              <span
                                style={{
                                  cursor: "pointer",
                                  marginLeft: "20px",
                                }}
                                onClick={() => {
                                  setWhiteSpaceDiv(true);
                                  dispatch(setStoresMaxPageSoFar(0));
                                  dispatch(setStoresCurrentPage(0));
                                  dispatch(
                                    setStoreDateRange({ aoe: null, boe: null })
                                  );
                                  dispatch(
                                    fetchStores(
                                      0,
                                      searchKeyword,
                                      null,
                                      null,
                                      storesPerPage
                                    )
                                  );
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </span>
                            )}
                          </Box>
                        </Box>
                      </DateRangePicker>
                    </div>
                  </div>
                  <TableContainer>
                    <Table>
                      <TableHead className={classes.tablehead}>
                        <TableRow className={classes.headrow}>
                          <TableCell style={{ width: "20%" }}>
                            Store Name
                          </TableCell>
                          <TableCell style={{ width: "13%" }}>Phone</TableCell>
                          <TableCell style={{ width: "10%" }}>
                            Created At
                          </TableCell>
                          <TableCell
                            style={{ width: "12%", textAlign: "right" }}
                          >
                            Invoice Count
                          </TableCell>
                          <TableCell
                            style={{ width: "13%", textAlign: "right" }}
                          >
                            Product Count
                          </TableCell>
                          <TableCell
                            style={{ width: "13", textAlign: "right" }}
                          >
                            Customer Count
                          </TableCell>
                          <TableCell style={{ textAlign: "center" }}>
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody className={classes.tablebody}>
                        {stores
                          .slice(startIndex, endIndex)
                          .map((tableitem, index) => (
                            <TableRow
                              key={tableitem.id}
                              style={{
                                borderBottom:
                                  (index + 1) % storesPerPage === 0
                                    ? "none"
                                    : "auto",
                              }}
                            >
                              <TableCell>{tableitem.store_name}</TableCell>
                              <TableCell>{tableitem.mobile}</TableCell>
                              <TableCell>
                                <Moment format="DD-MMM-YYYY">
                                  {tableitem.created_at}
                                </Moment>
                              </TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                {tableitem?.store_analytics?.invoice_count ?? 0}
                              </TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                {tableitem?.store_analytics?.product_count ?? 0}
                              </TableCell>
                              <TableCell style={{ textAlign: "right" }}>
                                {tableitem?.store_analytics?.customer_count ??
                                  0}
                              </TableCell>

                              <TableCell style={{ textAlign: "center" }}>
                                <Button
                                  // color="secondary"
                                  style={{
                                    width: 110,
                                    height: 32,
                                    outline: "none",
                                    textTransform: "none",
                                    fontSize: 12,
                                    padding: 8,
                                  }}
                                  variant="outlined"
                                  onClick={() =>
                                    onLoginClick(tableitem.store_id)
                                  }
                                >
                                  <LoginIcon />
                                  &nbsp;&nbsp;&nbsp;Login
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                      </TableBody>
                    </Table>

                    {storeState.progress && (
                      <Fragment>
                        {!whiteSpaceDiv && (
                          <div
                            style={{
                              height: 69 * storesPerPage - 4,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          ></div>
                        )}
                        <LinearProgress color="secondary" />
                      </Fragment>
                    )}
                    {stores.length === 0 && (
                      <div
                        style={{
                          height: 69 * storesPerPage,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            marginTop: -34,
                          }}
                        >
                          No Store Found
                        </Typography>
                      </div>
                    )}
                  </TableContainer>
                </div>
                <Paginator
                  changeRecordsPerPage={onChangeStoresPerPage}
                  possibleRecordsPerPage={[
                    DEFAULT_STORES_PER_PAGE,
                    20,
                    50,
                    100,
                  ]}
                  recordsPerPage={storesPerPage}
                  onNext={onNextPage}
                  onPrev={onPrevPage}
                  total={numberOfPages === 0 ? 1 : numberOfPages}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </Grid>
      </Grid>
      <Toast
        severity="error"
        open={toast}
        close={() => setToast(false)}
        message={"Something went wrong, please try again!"}
      />
    </Fragment>
  );
};
export default Stores;
