import { CLEAR_DATA, CLEAR_COMPLETE } from "../commom/commonTypes";
import {
  FETCH_STORES,
  SET_STORE_ERROR,
  SET_STORE_LOADER,
  STORE_PROGRESS,
  CLEAR_STORE,
  CLEAR_COMPLETE_STORES,
  SET_STORES_PER_PAGE,
  SET_STORES_SEARCH_KEYWORD,
  SET_STORES_INPUT_KEYWORD,
  SET_STORES_CURRENT_PAGE,
  SET_STORES_MAX_PAGE_SO_FAR,
  SET_SUCCESS_TOAST,
  STORE_DATE_RANGE,
} from "./storeTypes";

const initialState = {
  stores: [],
  numberOfPages: 0,
  numberOfElements: 0,
  store: null,
  progress: true,
  loader: false,
  error: null,
  pageDirty: false,
  storesPerPage: null,
  currentPage: 0,
  maxPageSoFar: 0,
  searchKeyword: "",
  inputKeyword: "",
  dateRange: {
    aoe: null,
    boe: null,
  },
  successToast: false,
  message: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case STORE_PROGRESS:
      return {
        ...state,
        progress: action.payload,
      };
    case FETCH_STORES:
      let allStores = state.stores;
      let page = action.payload.pageable.pageNumber;
      if (page > 0 && state.stores.length > action.storesPerPage * page) {
        allStores = state.stores.slice(0, action.storesPerPage * page);
      }
      return {
        ...state,
        pageDirty: false,
        stores:
          action.payload.pageable.pageNumber > 0
            ? [...allStores, ...action.payload.content]
            : action.payload.content,
        numberOfPages: action.payload.totalPages,
        numberOfElements: action.payload.totalElements,
        progress: false,
      };

    case CLEAR_STORE:
      return {
        ...state,
        store: action.payload,
      };
    case SET_STORE_ERROR:
      return {
        ...state,
        error: action.payload.error,
        progress: false,
      };
    case SET_STORE_LOADER:
      return {
        ...state,
        loader: action.payload,
      };
    case CLEAR_COMPLETE_STORES:
      return {
        ...state,
        stores: [],
        numberOfPages: 0,
        numberOfElements: 0,
        store: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        successToast: false,
        message: "",
      };
    case CLEAR_COMPLETE:
    case CLEAR_DATA:
      return {
        ...state,
        stores: [],
        numberOfPages: 0,
        numberOfElements: 0,
        store: null,
        progress: false,
        loader: false,
        error: null,
        pageDirty: false,
        storesPerPage: null,
        searchKeyword: "",
        inputKeyword: "",
        currentPage: 0,
        maxPageSoFar: 0,
        dateRange: {
          aoe: null,
          boe: null,
        },
        successToast: false,
        message: "",
      };
    case SET_STORES_PER_PAGE:
      return {
        ...state,
        storesPerPage: action.payload,
      };
    case SET_STORES_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.payload,
      };
    case SET_STORES_MAX_PAGE_SO_FAR:
      return {
        ...state,
        maxPageSoFar: action.payload,
      };
    case SET_STORES_INPUT_KEYWORD:
      return {
        ...state,
        inputKeyword: action.payload,
      };
    case SET_STORES_SEARCH_KEYWORD:
      return {
        ...state,
        searchKeyword: action.payload,
      };
    case STORE_DATE_RANGE:
      return {
        ...state,
        dateRange: action.payload,
      };
    case SET_SUCCESS_TOAST:
      return {
        ...state,
        successToast: action.payload,
        meassage: "",
      };
    default:
      return state;
  }
};

export default reducer;
