import defaultTheme from "./default";
import { createMuiTheme } from "@material-ui/core";
const overrides = {
  typography: {
    fontFamily: `"Noto Sans", "Roboto", "Arial"`,
    h1: {
      fontSize: "3rem",
    },
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.64rem",
    },
    h4: {
      fontSize: "24px",
      lineHeight: "32px",
      color: "#000000",
      fontWeight: "bold",
    },
    h5: {
      fontSize: "1.285rem",
    },
    h6: {
      fontSize: "1.142rem",
    },
  },
};

export default {
  default: createMuiTheme({ ...defaultTheme, ...overrides }),
};
