import {
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableRow,
  TableCell,
  TableHead,
  Typography,
  Button,
} from "@material-ui/core";
import React, { useState } from "react";
import Moment from "react-moment";
import StatusDotIcon from "../../../icons/StatusDotIcon";
import {
  rowFlexAlignCenter,
  rowFlexOnlyJustifyBetweenCenter,
} from "../../../styles/common";
import ChargeDetails from "./ChargeDetails";
import SmallDiamond from "./SmallDiamond";
import { planCustomProps, PLANS } from "./Constants";
import DomainModal from "./DomainModal";
import PlanModal from "./PlanModal";
import ShoopyPayModal from "./ShoopyPayModal";
import WalletIcon from "./WalletIcon";
import WalletModal from "./WalletModel";
import axios from "axios";
import { getPartner } from "../../../utils/partnerUtils";

const useStyles = makeStyles(() => ({
  dividerGrid: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingTop: 24,
    paddingBottom: 24,
    borderBottom: "1px solid #E6E6E6",
    width: "100%",
  },
  flexBox: {
    display: "flex",
    justifyContent: "space-between",
    alignContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  subHeading: {
    fontWeight: 600,
    fontSize: 14,
    color: "#1A1A1A",
    paddingTop: 0,
  },
  nopicctn: {
    border: "1px solid #E5E5E5",
    boxSizing: "border-box",
    borderRadius: "6px",
    width: 60,
    height: 60,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  prodimage: {
    height: "60px",
    width: "60px",
    borderRadius: "6px",
  },
  earnCont: {
    borderRadius: "12px",
    background: "#FFF9F4",
    padding: "12px",
    display: "flex",
    alignItems: "center",
    marginTop: "15px",
    height: "52px",
  },
}));

const stateMapper = (store) => {
  let status = store.status;
  let info = {};
  switch (status) {
    case "ACTIVE":
      info = { text: "Active", fill: "#1641DB" };
      break;
    case "CANCELED":
    case "DELETED":
      info = { text: "Canceled", fill: "#FF0000" };
      break;
    case "SUSPENDED":
      info = { text: "Suspended", fill: "#808080" };
      break;
    default:
      info = { text: "Unknown", fill: "#FFA500" };
  }
  return info;
};

function getPayemntInfo(store) {
  const returnBoolean = (value) => (value === "true" ? true : false);

  let payment_settings = store.attributes?.payment_settings;
  let paymentInfo = {};
  if (payment_settings) {
    paymentInfo = {
      force_cod: returnBoolean(payment_settings.force_cod),

      shoopy_kyc_done: returnBoolean(payment_settings.shoopy_kyc_done),

      shoopy_per_transaction_limit:
        payment_settings.shoopy_per_transaction_limit,
    };
  } else {
    paymentInfo = {
      force_cod: false,
      shoopy_kyc_done: false,
      shoopy_per_transaction_limit: 0,
    };
  }
  return paymentInfo;
}

function getSubscriptionPlanInfo(store) {
  let subscription = store.subscription;
  let planInfo = {};
  if (subscription) {
    let planDtls = PLANS[subscription.plan];
    planInfo = {
      name: planDtls.name,
      description: "Plan Description",
      plan: subscription.plan,
      amount: subscription.renewal_amount,
      refer_and_earn: subscription.refer_and_earn,
      indiamart: subscription.indiamart,
      end_date: subscription.end_date,
      start_date: subscription.start_date,
      activation_reason: subscription.activation_reason,
      trial: subscription.trial,
      agent_count:
        subscription.agent_count > 0
          ? subscription.agent_count
          : planDtls.users,
    };
  } else {
    planInfo = {
      name: "Free",
      description: "Free Plan Store",
      activation_reason: "none",
      plan: "FREE",
      amount: 0,
      refer_and_earn: false,
      indiamart: false,
      trial: true,
      agent_count: 0,
      start_date: store.created_at,
    };
  }
  return planInfo;
}
const StoreDtl = ({ store, onChange }) => {
  const classes = useStyles();
  const [storeState, setStoreState] = useState(store);
  const [domain, setDomain] = useState(store.domain);
  const [domainModal, setDomainModal] = useState(false);

  const [paymentInfo, setPaymentInfo] = useState(getPayemntInfo(store));
  const [PayModal, setPayModal] = useState(false);

  const [planInfo, setPlanInfo] = useState(getSubscriptionPlanInfo(store));
  const [planModal, setPlanModal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [walletModal, setWalletModal] = useState(false);
  const [walletBalance, setWalletBalance] = useState(
    store?.wallet_balance || 0
  );

  const onDomainSave = async (upStore) => {
    setDomainModal(false);
    setDomain(upStore.domain);
    setStoreState(upStore);
    onChange(upStore);
  };

  const onDomainClose = () => {
    setDomainModal(false);
  };

  const onShoopyPaySave = async (upStore) => {
    setPayModal(false);
    setPaymentInfo(getPayemntInfo(upStore));
    setStoreState(upStore);
    onChange(upStore);
  };

  const onShoopyPayClose = () => {
    setPayModal(false);
  };

  const onPlanSave = async (upStore) => {
    setPlanModal(false);
    setPlanInfo(getSubscriptionPlanInfo(upStore));
    setStoreState(upStore);
    onChange(upStore);
  };

  const onPlanClose = () => {
    setPlanModal(false);
  };

  const onWalletSave = async (txnType, amount) => {
    try {
      setLoading(true);
      let partner = getPartner();
      let accessToken = localStorage.getItem("access_token");
      const response = await axios.post(
        `${process.env.REACT_APP_AUTH_API}/api/v1/partner/${partner.partner_slug}/shoopy-stores/${storeState.store_id}/wallet`,
        {
          type: txnType,
          amount: parseInt(amount),
	  source: "MANUAL"
	},
        {
          headers: {
            "Content-Type": "application/json",
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const { data } = response;
      const { wallet_balance } = data.payload;
      setWalletBalance(wallet_balance);
      setLoading(false);
      setWalletModal(false);
    } catch (error) {
      setLoading(false);
      console.log("error", error);
    }
  };

  const onWalletClose = () => {
    setWalletModal(false);
  };

  return (
    <>
      <div className={classes.dividerGrid}>
        <Typography className={classes.subHeading}>Store Status</Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            textTransform: "capitalize",
          }}
        >
          <StatusDotIcon fill={stateMapper(storeState).fill} />
          &nbsp; &nbsp;
          {stateMapper(storeState).text}
        </div>
      </div>
      <div className={classes.dividerGrid}>
        <Typography className={classes.subHeading}>Create Date</Typography>
        <Moment format="DD-MMM-YYYY, hh:mm A">{storeState.created_at}</Moment>
      </div>
      <div className={classes.dividerGrid}>
        <Typography className={classes.subHeading}>Phone</Typography>
        <span>{storeState.mobile}</span>
      </div>
      <div className={classes.dividerGrid}>
        <Typography className={classes.subHeading}>Domain</Typography>
        <div className={rowFlexAlignCenter}>
          <p style={{ marginBottom: 0 }}>{domain}</p>
          <Button
            variant="text"
            color="secondary"
            className={classes.changebtn}
            onClick={() => {
              setDomainModal(true);
            }}
          >
            Change
          </Button>
        </div>
      </div>
      <div className={classes.dividerGrid}>
        <Typography className={classes.subHeading}>Slug</Typography>
        <span>{storeState.slug}</span>
      </div>
      <div className={classes.dividerGrid}>
        <Typography className={classes.subHeading}>Shoopy Pay</Typography>
        <div className={rowFlexAlignCenter}>
          <p style={{ marginBottom: 0 }}>
            Amount Limit- {paymentInfo.shoopy_per_transaction_limit}, KYC-
            {paymentInfo.shoopy_kyc_done ? "YES" : "NO"}
          </p>
          <Button
            variant="text"
            color="secondary"
            className={classes.changebtn}
            onClick={() => {
              setPayModal(true);
            }}
          >
            Change
          </Button>
        </div>
      </div>
      <div
        style={{
          borderBottom: "1px solid #E6E6E6",
          width: "100%",
          paddingBottom: 24,
        }}
      >
        <div
          className={classes.dividerGrid}
          style={{
            borderBottom: "none",
            paddingBottom: 18,
          }}
        >
          <Typography className={classes.subHeading}>Subscription</Typography>
        </div>
        <div className={rowFlexOnlyJustifyBetweenCenter}>
          <ChargeDetails
            diamond={
              <SmallDiamond {...planCustomProps[planInfo.plan].diamondProps} />
            }
            title={planInfo.name}
            endDate={planInfo.end_date}
            shortDescription={planInfo.description}
            amount={planInfo.amount}
            term="yearly"
          />
          <Button
            variant="text"
            color="secondary"
            className={classes.changebtn}
            onClick={() => {
              setPlanModal(true);
            }}
          >
            Change
          </Button>
        </div>
      </div>
      <div
        style={{
          borderBottom: "1px solid #E6E6E6",
          width: "100%",
          paddingBottom: 24,
        }}
      >
        <div
          className={classes.dividerGrid}
          style={{
            borderBottom: "none",
            paddingBottom: 18,
          }}
        >
          <Typography className={classes.subHeading}>Shoopy Wallet</Typography>
        </div>
        <div className={rowFlexOnlyJustifyBetweenCenter}>
          <div
            className={rowFlexOnlyJustifyBetweenCenter}
            style={{ width: "100%", marginTop: 8 }}
          >
            <div className={rowFlexAlignCenter}>
              <WalletIcon />
              <div
                style={{
                  marginLeft: 12,
                }}
              >
                <Typography
                  className={classes.charge}
                  style={{ fontSize: 16, lineHeight: "20px" }}
                >
                  {walletBalance}
                </Typography>
                <Typography
                  className={classes.secondary}
                  style={{
                    fontSize: 14,
                    lineHeight: "18px",
                    color: "#666666",
                    marginTop: 4,
                  }}
                >
                  Credits
                </Typography>
              </div>
            </div>
          </div>

          <Button
            variant="text"
            color="secondary"
            className={classes.changebtn}
            onClick={() => setWalletModal(true)}
          >
            Change
          </Button>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: 16,
          borderBottom: "1px dashed rgb(240,240,240)",
          marginBottom: 8,
        }}
      >
        <Typography className={classes.subHeading} style={{ marginBottom: 16 }}>
          Store Users
        </Typography>
        <TableContainer>
          <Table>
            <TableHead
              className={classes.tablehead}
              style={{ borderTop: "1px solid #E6E6E6" }}
            >
              <TableRow className={classes.headrow}>
                <TableCell style={{ paddingLeft: 0 }}>Name</TableCell>
                <TableCell style={{ textAlign: "center" }}>Mobile</TableCell>
                <TableCell style={{ textAlign: "right" }}>Role</TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={classes.tablebody}>
              {storeState.store_users &&
                storeState.store_users.map((tableitem, index) => (
                  <TableRow
                    key={tableitem.id}
                    style={{
                      borderBottom:
                        index === storeState.store_users.length - 1
                          ? "none"
                          : "auto",
                    }}
                  >
                    <TableCell style={{ paddingLeft: 0 }}>
                      {tableitem.name}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {tableitem.mobile}
                    </TableCell>
                    <TableCell style={{ textAlign: "right" }}>
                      {tableitem.role}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      {domainModal && (
        <DomainModal
          onSave={onDomainSave}
          onClose={onDomainClose}
          record={storeState}
        />
      )}
      {PayModal && (
        <ShoopyPayModal
          onSave={onShoopyPaySave}
          onClose={onShoopyPayClose}
          record={paymentInfo}
          storeId={storeState.store_id}
        />
      )}
      {planModal && (
        <PlanModal
          onSave={onPlanSave}
          onClose={onPlanClose}
          record={planInfo}
          storeId={storeState.store_id}
        />
      )}
      {walletModal && (
        <WalletModal
          open={walletModal}
          loading={loading}
          onSave={onWalletSave}
          onClose={onWalletClose}
        />
      )}
    </>
  );
};

export default StoreDtl;
