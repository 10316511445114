import { CUSTOMER_LOGIN_SUCCESS, CUSTOMER_LOGOUT } from "./customerTypes";
import axios from "axios";
import { setupPartner } from "../../utils/partnerUtils";

export const fetchPartner = () => async (dispatch) => {
  let accessToken = localStorage.getItem("access_token");
  return await axios
    .get(`${process.env.REACT_APP_API}/api/v1/partner-users`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${accessToken}`,
      },
    })
    .then((response) => {
      return response.data.payload;
    })
    .catch(() => {
      return {};
    });
};

export const customerLoginSuccess = (tokenData) => (dispatch) => {
  let token = tokenData["access_token"];
  localStorage.setItem("access_token", token);
  axios
    .get(`${process.env.REACT_APP_API}/api/v1/partner-users`, {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      if (response.data.payload) {
        setupPartner(response.data.payload);
      }
      dispatch({
        type: CUSTOMER_LOGIN_SUCCESS,
        payload: { customer: response.data.payload, token: token },
      });
      return response;
    });
};

export const customerLogout = () => (dispatch) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("partner");
  dispatch({
    type: CUSTOMER_LOGOUT,
    payload: {},
  });
};
