export const FETCH_STORES = "FETCH_STORES";
export const STORE_PROGRESS = "STORE_PROGRESS";
export const SET_STORE_ERROR = "SET_STORE_ERROR";
export const SET_STORE_LOADER = "SET_STORE_LOADER";
export const CLEAR_STORE = "CLEAR_STORE";
export const CLEAR_COMPLETE_STORES = "CLEAR_COMPLETE_STORES";
export const SET_STORES_PER_PAGE = "SET_STORES_PER_PAGE";
export const SET_STORES_CURRENT_PAGE = "SET_STORES_CURRENT_PAGE";
export const SET_STORES_MAX_PAGE_SO_FAR = "SET_STORES_MAX_PAGE_SO_FAR";
export const SET_STORES_INPUT_KEYWORD = "SET_STORES_INPUT_KEYWORD";
export const SET_STORES_SEARCH_KEYWORD = "SET_STORES_SEARCH_KEYWORD";
export const SET_SUCCESS_TOAST = "SET_SUCCESS_TOAST";
export const STORE_DATE_RANGE = "STORE_DATE_RANGE";
