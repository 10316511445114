import { Grid } from "@material-ui/core";
import axios from "axios";
import React, { useState } from "react";
import FormModal from "../../../components/common/FormModal";
import Controls from "../../../components/controls/Controls";
import SubtitleSelect from "../../../components/controls/SubtitleSelect";
import { formLabel, rowFlexAlignCenter } from "../../../styles/common";
import { getPartner } from "../../../utils/partnerUtils";
import { addYearstoDate, toLocaleISOString } from "../../../utils/utils";
import SmallDiamond from "./SmallDiamond";

export const ACTIVATION_OPTIONS = [
  {
    id: "Plan Purchased",
    title: "Plan Purchased",
  },
  {
    id: "Trial",
    title: "Free Trial",
  },
  {
    id: "Plan Renewed",
    title: "Plan Renewed",
  },
];
const PLANOPTIONS = [
  {
    value: "FREE",
    title: "Free Plan",
    displayValue: "Free Plan",
    subtitle:
      "Free Plan offers store under shoopy subdomain and limited products",
    icon: () => <SmallDiamond ellipse="#82f2ee" />,
  },
  {
    value: "BASIC",
    title: "Basic Plan",
    displayValue: "Basic Plan",
    subtitle:
      "Basic Plan offers Domain Mapping and no Shoopy Branding except SMS",
    icon: () => <SmallDiamond />,
  },
  {
    value: "BUSINESS_APP_PRO",
    title: "Professional Plan",
    displayValue: "Professional Plan",
    subtitle:
      "Professional Plan - Basic Features + Android App + No Shoopy Branding in SMS",
    plan: "BUSINESS_APP_PRO",
    icon: () => (
      <SmallDiamond
        primary="#FF1D1D"
        secondary="#2238A8"
        tertiary="#8715F4"
        quaternary="#B0B5F1"
        quinary="#9973EF"
        ellipse="#CF9EFF"
      />
    ),
  },
  {
    value: "BUSINESS",
    title: "Business Plan",
    displayValue: "Business Plan",
    subtitle:
      "Business Plan - Professionla Features + Store Setup + Dedicated Account Manager",
    plan: "BUSINESS",
    icon: () => <SmallDiamond ellipse="#DECC2B" />,
  },
];

const PlanModal = ({ storeId, record, onSave, onClose }) => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(true);
  const initState = {
    plan: {
      value: record.plan ?? "FREE",
    },
    start_date: {
      value: toLocaleISOString(
        record.start_date ? new Date(record.start_date) : new Date()
      ),
    },
    end_date: {
      value:
        record.plan !== "FREE"
          ? toLocaleISOString(
              record.end_date
                ? new Date(record.end_date)
                : addYearstoDate(new Date(), 1)
            )
          : "",
    },
    activation_reason: {
      value: record.trial
        ? "Trial"
        : record.activation_reason && /renew/i.test(record.activation_reason)
        ? "Plan Renewed"
        : "Plan Purchased",
    },
    agent_count: {
      value: record.agent_count ?? 0,
    },
    trial: {
      value: record.trial ?? false,
    },
    refer_and_earn: {
      value: record.refer_and_earn ?? false,
    },
    indiamart: {
      value: record.indiamart ?? false,
    },
    amount: {
      value: record.amount ?? 0,
    },
  };

  const updatePlan = async () => {
    let accessToken = localStorage.getItem("access_token");
    let data = {
      activation_reason: formState.activation_reason.value,
      agent_count: formState.agent_count.value,
      trial: /trial/i.test(formState.activation_reason.value),
      end_date: new Date(formState.end_date.value),
      start_date: new Date(formState.start_date.value),
      plan: formState.plan.value,
      renewal_amount: formState.amount.value,
      refer_and_earn: formState.refer_and_earn.value,
      indiamart: formState.indiamart.value,
    };
    let partner = getPartner();
    let response = await axios.post(
      `${process.env.REACT_APP_API}/api/v1/partner/${partner.partner_slug}/shoopy-stores/${storeId}/subscription`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    return response.data.payload;
  };

  const onFormFieldChange = (fieldName, fieldValue) => {
    const clonedState = { ...formState };
    clonedState[fieldName].value = fieldValue;
    clonedState[fieldName].error = false;
    setFormState(clonedState);
  };

  const setFieldsError = (fields) => {
    const clonedState = { ...formState };
    fields.forEach((fld) => {
      clonedState[fld].error = true;
    });
    setFormState(clonedState);
  };

  const [formState, setFormState] = useState(initState);
  return (
    <FormModal
      heading="Store Subscription Plan"
      open={open}
      onClose={() => {
        setOpen(false);
        onClose();
      }}
      onSave={async () => {
        let errorFlds = [];
        if (formState.plan.value === "") {
          errorFlds.push("plan");
        }
        if (formState.end_date.value === "") {
          errorFlds.push("end_date");
        }
        if (formState.start_date.value === "") {
          errorFlds.push("start_date");
        }
        if (errorFlds.length > 0) {
          setFieldsError(errorFlds);
          return;
        }
        setLoading(true);
        let upStore = await updatePlan(formState);
        setLoading(false);
        setOpen(false);
        onSave(upStore);
      }}
      loading={loading}
    >
      <div style={{ height: 400, overflow: "overlay" }}>
        <Grid container spacing={0}>
          <Grid item xs={12} style={{ marginBottom: 6 }}>
            <SubtitleSelect
              optionList={PLANOPTIONS}
              value={formState.plan.value}
              onChange={(val, plan) => {
                onFormFieldChange("plan", val);
              }}
              label="Select Plan"
              error={formState.plan.error ? "Select Plan" : ""}
              labelClass={formLabel}
              wrapperStyle={{ marginBottom: "16px" }}
            />
          </Grid>
          <Grid item xs={6} style={{ paddingRight: 20 }}>
            <Controls.Input
              type="date"
              name="start_date"
              label="Start Date"
              value={formState.start_date.value}
              onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
              onFocus={() => {}}
              onBlur={() => {}}
              error={formState.start_date.error ? "Provide Start Date" : ""}
              labelPlacement="top"
            />
          </Grid>
          <Grid item xs={6}>
            <Controls.Input
              type="date"
              name="end_date"
              label="End Date"
              value={formState.end_date.value}
              onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
              onFocus={() => {}}
              onBlur={() => {}}
              error={formState.end_date.error ? "Provide End Date" : ""}
              labelPlacement="top"
            />
          </Grid>
          <Grid item xs={6} style={{ paddingRight: 20, marginTop: 16 }}>
            <Controls.Input
              type="number"
              name="amount"
              label="Renewal Amount"
              value={formState.amount.value}
              onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
              onFocus={() => {}}
              onBlur={() => {}}
              labelPlacement="top"
            />
          </Grid>
          <Grid item xs={6} style={{ marginTop: 16 }}>
            <Controls.Select
              options={ACTIVATION_OPTIONS}
              name="activation_reason"
              value={formState.activation_reason.value}
              label="Activation Reason"
              onChange={(e) => onFormFieldChange(e.target.name, e.target.value)}
              labelPlacement="top"
            />
          </Grid>
          <Grid
            container
            spacing={0}
            className={rowFlexAlignCenter}
            style={{ marginTop: 16 }}
          >
            <Grid item xs={4} style={{ paddingRight: 20 }}>
              <Controls.Input
                type="number"
                name="agent_count"
                label="Agent Count"
                value={formState.agent_count.value}
                onChange={(e) =>
                  onFormFieldChange(e.target.name, e.target.value)
                }
                onFocus={() => {}}
                onBlur={() => {}}
                labelPlacement="top"
              />
            </Grid>
            <Grid
              item
              xs={4}
              style={{ paddingRight: 20, textAlign: "center", marginTop: 16 }}
            >
              <Controls.Checkbox
                name="refer_and_earn"
                label="Refer Earn"
                style={{ marginRight: 0, marginLeft: 0 }}
                checkboxStyles={{ padding: 0, marginRight: "12px" }}
                value={formState.refer_and_earn.value}
                color="secondary"
                onChange={(e) => {
                  onFormFieldChange(e.target.name, e.target.checked);
                }}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: "center", marginTop: 16 }}>
              <Controls.Checkbox
                name="indiamart"
                label="Indiamart"
                style={{ marginRight: 0, marginLeft: 0 }}
                checkboxStyles={{ padding: 0, marginRight: "12px" }}
                value={formState.indiamart.value}
                color="secondary"
                onChange={(e) => {
                  onFormFieldChange(e.target.name, e.target.checked);
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </div>
    </FormModal>
  );
};

export default PlanModal;
