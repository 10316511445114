import React, { useState } from "react";
import ShoopyIcon from "../../../icons/ShoopyIconLatest";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import MobileForm from "./MobileForm";
import OTPVerifyForm from "./OTPVerifyForm";
import HeartIcon from "../../../icons/HeartIcon";
import IndiaFlagIcon from "../../../icons/IndiaFlagIcon";
import EditIcon from "../../../icons/EditIcon";

const SignIn = ({ onSuccess, otpSent, setOtpSent, onSubmit }) => {
  const classes = useStyles();
  const [mobile, setMobile] = useState("");

  const onOtpSuccess = (mobile, isNew) => {
    //TODO As per hiding create account flow in Desktop app due to bug
    //Enable it back once we resolve it
    setMobile(mobile);
    setOtpSent(true);
  };

  const onChangeNum = () => {
    setOtpSent(false);
  };
  return (
    <>
      <div className={classes.icon}>
        <ShoopyIcon width={72} height={72} viewBox="0 0 40 40" />
        {/* <img src="https://ninjacart.in/images/logo.png" /> */}
      </div>
      <div className={classes.signin}>
        <div style={{ maxWidth: 400, width: "100%", padding: "0 20px" }}>
          <React.Fragment>
            <div>
              <Typography variant="h2" className={classes.heading}>
                Sign In
              </Typography>
              <div className={classes.subContainer}>
                {otpSent && (
                  <React.Fragment>
                    <Typography className={classes.subheading}>
                      OTP sent to <span>+91{mobile}</span>
                    </Typography>
                    <div onClick={onChangeNum}>
                      <EditIcon stroke="#1641DB" /> &nbsp;Change
                    </div>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div>
              {otpSent ? (
                <OTPVerifyForm
                  onSubmit={onSubmit}
                  mobile={mobile}
                  onSuccess={onSuccess}
                />
              ) : (
                <MobileForm onOtpSuccess={onOtpSuccess} />
              )}
            </div>
          </React.Fragment>
          <div className={classes.madeinindiactn}>
            <div className={classes.madeinindiamsg}>
              <Typography style={{ fontSize: 10, lineHeight: "12px" }}>
                Made with &nbsp;
              </Typography>
              <HeartIcon />
              <Typography style={{ fontSize: 10, lineHeight: "12px" }}>
                &nbsp; in INDIA &nbsp;
              </Typography>
              <IndiaFlagIcon />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default SignIn;
