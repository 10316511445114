import { SHOOPY_STORES_PER_PAGE } from "../../utils/utils";
import {
  FETCH_SHOOPY_STORES,
  SHOOPY_STORE_PROGRESS,
  SET_SHOOPY_STORE_ERROR,
  SET_SHOOPY_STORE_LOADER,
  CLEAR_SHOOPY_STORE,
  CLEAR_SHOOPY_COMPLETE_STORES,
  SET_SHOOPY_STORES_PER_PAGE,
  SET_SHOOPY_STORES_INPUT_KEYWORD,
  SET_SHOOPY_STORES_SEARCH_KEYWORD,
  SET_SHOOPY_STORES_CURRENT_PAGE,
  SET_SHOOPY_STORES_MAX_PAGE_SO_FAR,
  SET_SHOOPY_SUCCESS_TOAST,
  UPDATE_SHOOPY_STORE,
} from "./shoopyStoreTypes";
import axios from "axios";
import { getPartner } from "../../utils/partnerUtils";

export const fetchShoopyStores =
  (page = 0, keyword, size = SHOOPY_STORES_PER_PAGE) =>
  (dispatch) => {
    dispatch({
      type: SHOOPY_STORE_PROGRESS,
      payload: true,
    });
    let accessToken = localStorage.getItem("access_token");
    let partner = getPartner();
    axios
      .get(
        `${process.env.REACT_APP_API}/api/v1/partner/${
          partner.partner_slug
        }/shoopy-stores?attributes=payment_settings&page=${page}&size=${size}&sort=createdAt,desc${
          keyword ? `&query=${keyword}` : ""
        }`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      )
      .then((response) => {
        dispatch({
          type: FETCH_SHOOPY_STORES,
          payload: response.data.payload,
          storesPerPage: size,
        });
      })
      .catch((error) => {
        dispatch({
          type: SET_SHOOPY_STORE_ERROR,
          payload: { error: error.message },
        });
      });
  };

export const clearShoopyStore = () => (dispatch) => {
  dispatch({ type: CLEAR_SHOOPY_STORE, payload: null });
};

export const clearShoopyStores = () => (dispatch) => {
  dispatch({
    type: CLEAR_SHOOPY_COMPLETE_STORES,
  });
};

export const startShoopyStoreLoader = () => (dispatch) => {
  dispatch({
    type: SET_SHOOPY_STORE_LOADER,
    payload: true,
  });
};

export const stopShoopyStoreLoader = () => (dispatch) => {
  dispatch({
    type: SET_SHOOPY_STORE_LOADER,
    payload: false,
  });
};

export const setShoopyStoresPerPage = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOOPY_STORES_PER_PAGE,
    payload: data,
  });
};

export const setShoopyStoresCurrentPage = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOOPY_STORES_CURRENT_PAGE,
    payload: data,
  });
};

export const updateShoopyStore = (upStore) => (dispatch) => {
  dispatch({
    type: UPDATE_SHOOPY_STORE,
    payload: upStore,
  });
};

export const setShoopyStoresMaxPageSoFar = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOOPY_STORES_MAX_PAGE_SO_FAR,
    payload: data,
  });
};

export const setShoopyStoresInputKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOOPY_STORES_INPUT_KEYWORD,
    payload: data,
  });
};

export const setShoopyStoresSearchKeyword = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOOPY_STORES_SEARCH_KEYWORD,
    payload: data,
  });
};
export const setShoopySuccessToast = (data) => (dispatch) => {
  dispatch({
    type: SET_SHOOPY_SUCCESS_TOAST,
    payload: data,
  });
};
