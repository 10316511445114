import { TextField, Typography } from "@material-ui/core";
import React from "react";
import { rowFlexAlignCenter } from "../../styles/common";

const Input = ({
  label = "",
  labelPlacement = "start",
  labelFontWeight = 600,
  width,
  ...props
}) => {
  const endAdornment = props.endAdornment;
  const startAdornment = props.startAdornment;
  const maxLength = props.maxLength;
  const error = props.error;
  const onEnter = props.onEnter;
  const onKeyDown = props.onKeyDown;
  const onKeyPress = props.onKeyPress;
  const preventDecimal = props.preventDecimal;
  delete props.endAdornment;
  delete props.startAdornment;
  delete props.maxLength;
  delete props.error;
  delete props.onEnter;
  delete props.onKeyDown;
  delete props.onKeyPress;
  delete props.preventDecimal;

  return (
    <div className={labelPlacement === "start" ? rowFlexAlignCenter : ""}>
      {label && (
        <Typography
          style={{
            fontSize: 14,
            fontWeight: labelFontWeight,
            minWidth:
              labelPlacement === "start"
                ? props.labelWidth
                  ? props.labelWidth
                  : 110
                : 0,
            maxWidth: labelPlacement === "start" ? 110 : "auto",
            marginRight: labelPlacement === "start" ? 8 : 0,
            marginTop: labelPlacement === "start" ? -18 : 0,
            marginBottom: labelPlacement === "top" ? 12 : 0,
          }}
        >
          {label}
        </Typography>
      )}
      <div
        style={
          labelPlacement === "start"
            ? {
                width: width ? width : "100%",
                ...(!width && { flexGrow: 1 }),
              }
            : { width: width ? width : "100%" }
        }
      >
        <TextField
          color="secondary"
          variant={props?.variant ?? "outlined"}
          InputProps={{
            ...(endAdornment && { endAdornment }),
            ...(startAdornment && { startAdornment }),
            ...props.InputProps,
          }}
          autoFocus={props.autoFocus ? true : false}
          error={!!error}
          {...props}
          fullWidth
          onChange={(e) => {
            if (maxLength && e.target.value.length > maxLength) {
              return;
            }
            props.onChange && props.onChange(e);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onEnter && onEnter(e);
            }
            onKeyDown && onKeyDown(e);
          }}
          onKeyPress={(e) => {
            if (preventDecimal)
              if (!(e.charCode >= 48 && e.charCode <= 57)) {
                e.preventDefault();
              }
            onKeyPress && onKeyPress(e);
          }}
        />
        <Typography
          style={{
            color: error ? "#F44336" : "#FFFFFF",
            fontSize: 12,
            margin: "0px 2px",
          }}
        >
          {error ? error : <span>h</span>}
        </Typography>
      </div>
    </div>
  );
};

export default Input;
