import React, { useState } from "react";
import { TextField, InputAdornment } from "@material-ui/core";
import PhoneButtonIcon from "../../../icons/PhoneButtonIcon";
import useStyles from "./styles";
import axios from "axios";
import SolidButton from "../../../components/buttons/SolidButton";

const MOBILE_MAX_LENGTH = 10;
const MobileForm = ({ onOtpSuccess }) => {
  const classes = useStyles();
  const [inputs, setInputs] = useState({ mobile: "", error: false });
  const handleSubmit = (event) => {
    event.preventDefault();
    let mobile = inputs["mobile"];
    if (!mobile || mobile.length !== MOBILE_MAX_LENGTH) {
      setInputs((inputs) => ({ ...inputs, error: true }));
    } else {
      if (window.location.search.includes("nootp")) {
        onOtpSuccess(inputs["mobile"]);
      } else {
        getOTP(inputs["mobile"]).then((res) => {
          onOtpSuccess(inputs["mobile"], res.is_new);
        });
      }
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
      error: false,
    }));
  };

  const getOTP = async (mobile) => {
    let response = await axios.post(
      `${process.env.REACT_APP_AUTH_API}/api/v1/auth/oauth/otp-customer`,
      `${mobile}`,
      {
        headers: {
          "Content-Type": "text/plain",
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
        },
      }
    );
    return response.data;
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <TextField
        margin="normal"
        id="mobile"
        label="Mobile Number"
        name="mobile"
        error={inputs.error}
        helperText={
          inputs.error && inputs.mobile
            ? "Please provide a correct mobile number"
            : " "
        }
        autoComplete="mobile"
        inputProps={{ maxLength: 10 }}
        type="number"
        autoFocus
        onInput={(e) => {
          e.target.value = Math.max(0, parseInt(e.target.value))
            .toString()
            .slice(0, MOBILE_MAX_LENGTH);
        }}
        onChange={handleInputChange}
        value={inputs.mobile}
        color="secondary"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <PhoneButtonIcon />
            </InputAdornment>
          ),
          classes: {
            input: classes.input,
          },
        }}
        InputLabelProps={{ classes: { root: classes.label } }}
        style={{ maxWidth: 360, width: "100%" }}
        onFocus={() => {
          setInputs({ ...inputs, error: false });
        }}
      />
      <SolidButton
        style={{ width: "100%" }}
        disabled={inputs.mobile.length < 10}
      >
        Request OTP
      </SolidButton>
    </form>
  );
};

export default MobileForm;
