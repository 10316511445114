import { Drawer, Grid, IconButton } from "@material-ui/core";
import React from "react";
import CloseIcon from "../../../icons/CloseIcon";
import StoreDtl from "./StoreDtl";

const ViewStore = ({ store, onClose, onChange }) => {
  return (
    <Drawer
      open={true}
      onClose={onClose}
      anchor="right"
      PaperProps={{
        style: {
          borderTopLeftRadius: 16,
          borderBottomLeftRadius: 16,
          padding: 52,
          paddingRight: 42,
          width: 570,
        },
      }}
    >
      <Grid container style={{ position: "relative" }}>
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <p style={{ fontWeight: 700, fontSize: 24, marginTop: -10 }}>
            {store.store_name}
          </p>
          <IconButton
            aria-label="Close"
            aria-haspopup="true"
            color="inherit"
            onClick={onClose}
            style={{ marginTop: -26, outline: "none" }}
          >
            <CloseIcon />
          </IconButton>
        </Grid>
        <StoreDtl store={store} onChange={onChange} />
      </Grid>
    </Drawer>
  );
};

export default ViewStore;
