import { combineReducers } from "redux";
import customerReducer from "./customer/customerReducer";
import reloadReducer from "./reload/reloadReducer";
import storeReducer from "./stores/storeReducer";
import shoopyStoreReducer from "./shoopystores/shoopyStoreReducer";

const rootReducer = combineReducers({
  customer: customerReducer,
  reload: reloadReducer,
  stores: storeReducer,
  shoopystores: shoopyStoreReducer
});

export default rootReducer;
